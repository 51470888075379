<template>
	<div>
		<div class="record_date">
			<el-row>
				<el-col :span="6" class="cards">
					<div class="box">
						<div class="box_num">
							<div class="box_color">总用户数</div>
							<div class="text_color"><span>{{tableData.totalUsers ? tableData.totalUsers : 0}}</span>人
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6" class="cards">
					<div class="box">
						<div class="box_num">
							<div class="box_color">今日新增</div>
							<div class="text_color"><span>{{tableData.newToday ? tableData.newToday : 0}}</span>人</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6" class="cards">
					<div class="box">
						<div class="box_num">
							<div class="box_color">本月新增</div>
							<div class="text_color"><span>{{tableData.newMonth ? tableData.newMonth : 0}}</span>人</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6" class="cards">
					<div class="box">
						<div class="box_num">
							<div class="box_color">本年新增</div>
							<div class="text_color"><span>{{tableData.newYear ? tableData.newYear : 0}}</span>人</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6" class="cards">
					<div class="box">
						<div class="box_num">
							<div class="box_color">总充值 </div>
							<div class="text_color">
								<span>{{tableData.totalRevenue ? tableData.totalRevenue : 0}}</span>元</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6" class="cards">
					<div class="box">
						<div class="box_num">
							<div class="box_color">今日充值</div>
							<div class="text_color">
								<span>{{tableData.todayRevenue ? tableData.todayRevenue : 0 }}</span>元</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6" class="cards">
					<div class="box">
						<div class="box_num">
							<div class="box_color">本月充值 </div>
							<div class="text_color">
								<span>{{tableData.monthRevenue ? tableData.monthRevenue : 0}}</span>元</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6" class="cards">
					<div class="box">
						<div class="box_num">
							<div class="box_color">本年充值</div>
							<div class="text_color"><span>{{tableData.yearRevenue ? tableData.yearRevenue : 0}}</span>元
							</div>
						</div>
					</div>
				</el-col>

			</el-row>
			<div>
				<el-select v-model="flag" style="width:150px;margin-left: 10px;" @change="orderfenxi">
					<el-option v-for="item in flags" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>&nbsp;&nbsp;&nbsp;
				<el-date-picker style="width: 200px;margin-left: 10px;" v-model="info.stockDate" align="right"
					type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择开始时间"
					@change="animeOrder">
				</el-date-picker>
			</div>
			<el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left: 10px;">
				<el-tab-pane label="服务分析" name="first">
					<el-row>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">服务发单总金额</div>
									<div class="text_color">
										<span>{{taskStatF.takeOrderSum ? taskStatF.takeOrderSum : 0}}</span>元</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">服务接单总金额</div>
									<div class="text_color">
										<span>{{taskStatF.sendOrderSum ? taskStatF.sendOrderSum : 0}}</span>元</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">服务发单数量</div>
									<div class="text_color">
										<span>{{taskStatF.takeOrderCount ? taskStatF.takeOrderCount : 0}}</span>笔</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">服务接单数量</div>
									<div class="text_color">
										<span>{{taskStatF.sendOrderCount ? taskStatF.sendOrderCount : 0}}</span>笔</div>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">万能任务总金额</div>
									<div class="text_color">
										<span>{{taskStatF.helperOrderAllMoney ? taskStatF.helperOrderAllMoney : 0}}</span>元</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">万能任务完成总金额</div>
									<div class="text_color">
										<span>{{taskStatF.helperOrderCompleteMoney ? taskStatF.helperOrderCompleteMoney : 0}}</span>元</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">万能任务总订单数</div>
									<div class="text_color">
										<span>{{taskStatF.helperOrderAllCount ? taskStatF.helperOrderAllCount : 0}}</span>笔</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">万能任务完成总数</div>
									<div class="text_color">
										<span>{{taskStatF.helperOrderCompleteCount ? taskStatF.helperOrderCompleteCount : 0}}</span>笔</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="服务收入分析" name="fourth">
					<el-table v-loading="tableDataLoading" :data="tableData2.list">
						<el-table-column prop="orderId" label="编号">
						</el-table-column>
						<el-table-column prop="nickName1" label="用户名">
							<template slot-scope="scope">
								<span style="color: #4f9dec;cursor: pointer;"
									@click="updates1(scope.row)">{{scope.row.userName ? scope.row.userName : 'null'}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="payMoney" label="支付金额">
						</el-table-column>
						<el-table-column prop="money" label="派单人金额">
						</el-table-column>
						<el-table-column prop="profit" label="平台收入">
							<template slot-scope="scope">
								{{ parseFloat(scope.row.payMoney-scope.row.money).toFixed(2)}}
							</template>
						</el-table-column>
						<el-table-column prop="createTime" label="创建时间">
						</el-table-column>
					</el-table>
					<div style="text-align: center;margin-top: 10px;float:right">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:page-sizes="[5, 10, 15, 20]" :page-size="limit" :current-page="page"
							layout="total,sizes, prev, pager, next,jumper" :total="tableData2.totalCount">
						</el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="订单统计(总)" name="dingdanTj">
					<el-row>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">服务订单总金额</div>
									<div class="text_color">
										<span>{{taskStatTj.serviceMoney ? taskStatTj.serviceMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">服务订单总数量</div>
									<div class="text_color">
										<span>{{taskStatTj.serviceCount ? taskStatTj.serviceCount : 0}}</span>单
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">待服务(服务订单)</div>
									<div class="text_color">
										<span>{{taskStat.serviceDaiFuWuCount ? taskStat.serviceDaiFuWuCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">进行中(服务订单)</div>
									<div class="text_color">
										<span>{{taskStatTj.serviceJinXingCount ? taskStatTj.serviceJinXingCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">已完成(服务订单)</div>
									<div class="text_color">
										<span>{{taskStatTj.serviceWanChengCount ? taskStatTj.serviceWanChengCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">已退款(服务订单)</div>
									<div class="text_color">
										<span>{{taskStatTj.serviceTuiKuanCount ? taskStatTj.serviceTuiKuanCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
					<!-- </el-row>
					<el-row> -->
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">万能任务总金额</div>
									<div class="text_color">
										<span>{{taskStatTj.HelpOrderMoney ? taskStatTj.HelpOrderMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">万能任务总数量</div>
									<div class="text_color">
										<span>{{taskStatTj.HelpOrderCount ? taskStatTj.HelpOrderCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">待审核(万能任务)</div>
									<div class="text_color">
										<span>{{taskStatTj.HelpOrderDaiShenHeCount ? taskStatTj.HelpOrderDaiShenHeCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">待接单(万能任务)</div>
									<div class="text_color">
										<span>{{taskStatTj.HelpOrderDaiJieDanCount ? taskStatTj.HelpOrderDaiJieDanCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">待送达(万能任务)</div>
									<div class="text_color">
										<span>{{taskStatTj.HelpOrderDaiSongDaCount ? taskStatTj.HelpOrderDaiSongDaCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">已完成(万能任务)</div>
									<div class="text_color">
										<span>{{taskStatTj.HelpOrderYiWanChengCount ? taskStatTj.HelpOrderYiWanChengCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">已取消(万能任务)</div>
									<div class="text_color">
										<span>{{taskStatTj.HelpOrderYiQuXiaoCount ? taskStatTj.HelpOrderYiQuXiaoCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="订单分析" name="dingdan">
					<el-row>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">服务订单总金额</div>
									<div class="text_color">
										<span>{{taskStat.serviceMoney ? taskStat.serviceMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">待服务(服务订单)</div>
									<div class="text_color">
										<span>{{taskStat.serviceDaiFuWuCount ? taskStat.serviceDaiFuWuCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">进行中(服务订单)</div>
									<div class="text_color">
										<span>{{taskStat.serviceJinXingCount ? taskStat.serviceJinXingCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">已完成(服务订单)</div>
									<div class="text_color">
										<span>{{taskStat.serviceWanChengCount ? taskStat.serviceWanChengCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">已退款(服务订单)</div>
									<div class="text_color">
										<span>{{taskStat.serviceTuiKuanCount ? taskStat.serviceTuiKuanCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
					<!-- </el-row>
					<el-row> -->
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">万能任务总金额</div>
									<div class="text_color">
										<span>{{taskStat.HelpOrderMoney ? taskStat.HelpOrderMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">待审核(万能任务)</div>
									<div class="text_color">
										<span>{{taskStat.HelpOrderDaiShenHeCount ? taskStat.HelpOrderDaiShenHeCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">待接单(万能任务)</div>
									<div class="text_color">
										<span>{{taskStat.HelpOrderDaiJieDanCount ? taskStat.HelpOrderDaiJieDanCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">待送达(万能任务)</div>
									<div class="text_color">
										<span>{{taskStat.HelpOrderDaiSongDaCount ? taskStat.HelpOrderDaiSongDaCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">已完成(万能任务)</div>
									<div class="text_color">
										<span>{{taskStat.HelpOrderYiWanChengCount ? taskStat.HelpOrderYiWanChengCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">已取消(万能任务)</div>
									<div class="text_color">
										<span>{{taskStat.HelpOrderYiQuXiaoCount ? taskStat.HelpOrderYiQuXiaoCount : 0}}</span>笔
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="支付统计(总)" name="zhifu">
					<el-row>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">(服务任务)零钱支付</div>
									<div class="text_color">
										<span>{{taskStatZf.serviceLingQianMoney ? taskStatZf.serviceLingQianMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">(服务任务)微信支付</div>
									<div class="text_color">
										<span>{{taskStatZf.serviceWXMoney ? taskStatZf.serviceWXMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">(服务任务)支付宝支付</div>
									<div class="text_color">
										<span>{{taskStatZf.serviceZFBMoney ? taskStatZf.serviceZFBMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
					<!-- </el-row>
					<el-row> -->
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">(万能任务)零钱支付</div>
									<div class="text_color">
										<span>{{taskStatZf.HelpOrderLingQianMoney ? taskStatZf.HelpOrderLingQianMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">(万能任务)微信支付</div>
									<div class="text_color">
										<span>{{taskStatZf.HelpOrderWxMoney ? taskStatZf.HelpOrderWxMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">(万能任务)支付宝支付</div>
									<div class="text_color">
										<span>{{taskStatZf.HelpOrderZFBMoney ? taskStatZf.HelpOrderZFBMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="用户分析" name="user">
					<el-row>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">总用户人数</div>
									<div class="text_color">
										<span>{{taskStatUser.allUserCount ? taskStatUser.allUserCount : 0}}</span>人</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">会员用户人数</div>
									<div class="text_color">
										<span>{{taskStatUser.vipCount ? taskStatUser.vipCount : 0}}</span>人</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">普通用户人数</div>
									<div class="text_color">
										<span>{{taskStatUser.generalCount ? taskStatUser.generalCount : 0}}</span>人</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">商家用户人数</div>
									<div class="text_color">
										<span>{{taskStatUser.shopUserCount ? taskStatUser.shopUserCount : 0}}</span>人</div>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">师傅用户人数</div>
									<div class="text_color">
										<span>{{taskStatUser.workUserCount ? taskStatUser.workUserCount : 0}}</span>人</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">员工用户人数</div>
									<div class="text_color">
										<span>{{taskStatUser.staffUserCount ? taskStatUser.staffUserCount : 0}}</span>人</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">公众号用户人数</div>
									<div class="text_color">
										<span>{{taskStatUser.officialCount ? taskStatUser.officialCount : 0}}</span>人</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">小程序用户人数</div>
									<div class="text_color">
										<span>{{taskStatUser.courseCount ? taskStatUser.courseCount : 0}}</span>人</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">H5用户人数</div>
									<div class="text_color">
										<span>{{taskStatUser.h5Count ? taskStatUser.h5Count : 0}}</span>人</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="平台佣金统计" name="yongjin">
					<el-row>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">佣金总金额</div>
									<div class="text_color">
										<span>{{taskStatYj.allMoney ? taskStatYj.allMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">推广员金额</div>
									<div class="text_color">
										<span>{{taskStatYj.popularizeMoney ? taskStatYj.popularizeMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">平台金额</div>
									<div class="text_color">
										<span>{{taskStatYj.pingMoney ? taskStatYj.pingMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
					<!-- </el-row>
					<el-row> -->
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">商家金额</div>
									<div class="text_color">
										<span>{{taskStatYj.shopMoney ? taskStatYj.shopMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">师傅金额</div>
									<div class="text_color">
										<span>{{taskStatYj.workerMoney ? taskStatYj.workerMoney : 0}}</span>元
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="待处理事项" name="daichuli">
					<el-row>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">投诉待处理</div>
									<div class="text_color"><span @click="updatesD1()"
											style="color: red;cursor: pointer;">{{chatNumTs ? chatNumTs : 0}}</span>件
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">个人认证待审核</div>
									<div class="text_color">
										<span @click="updatesD2()"
											style="color: red;cursor: pointer;">{{chatNumSf ? chatNumSf : 0}}</span>件
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">企业认证待审核</div>
									<div class="text_color">
										<span @click="updatesD3()"
											style="color: red;cursor: pointer;">{{chatNumQy ? chatNumQy : 0}}</span>件
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">评价待审核</div>
									<div class="text_color">
										<span @click="updatesD4()"
											style="color: red;cursor: pointer;">{{chatNumPj ? chatNumPj : 0}}</span>件
									</div>
								</div>
							</div>
						</el-col>
						<el-col :span="6" class="cards">
							<div class="box">
								<div class="box_num">
									<div class="box_color">服务待审核</div>
									<div class="text_color">
										<span @click="updatesD5()"
											style="color: red;cursor: pointer;">{{chatNumFw ? chatNumFw : 0}}</span>件
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				limit: 5,
				page: 1,
				tableData: {},
				tableData2: {
					list: [],
					totalCount: 0
				},
				colonelData: [],
				commodityData: [],
				userjfData: [],
				incomeData: [],
				activeName: 'first',
				tableDataLoading: true,
				flag: 1,
				usersumData: {},
				taskStatF: {},
				taskStat: {},
				data: '',
				time: '',
				platform: '',
				type: '',
				balance: -1,
				flag: 1,
				info: {
					stockDate: this.getNowTime(), //日期
				},
				flags: [{
					value: 1,
					label: '按天查询'
				}, {
					value: 2,
					label: '按月查询'
				}, {
					value: 3,
					label: '按年查询'
				}],
				taskStatTj:{},
				taskStatZf:{},
				taskStatUser:{},
				taskStatYj:{},
				chatNumTs: 0,
				chatNumQy: 0,
				chatNumSf: 0,
				chatNumPj: 0,
				chatNumFw: 0,
			}
		},
		mounted() {
			this.colonel()
			this.taskData()
			this._getData() //用户统计
			this.taskDataD()
			this.taskDataZf()
			// this.incomeSelect()//收入
		},
		methods: {
			// 详情跳转
			updates(row) {
				this.$router.push({
					path: '/userDetail',
					query: {
						userId: row.userId
					}
				})
			},
			updates1(row) {
				this.$router.push({
					path: '/userDetail',
					query: {
						userId: row.userId1
					}
				})
			},
			updates2(row) {
				this.$router.push({
					path: '/userDetail',
					query: {
						userId: row.userId2
					}
				})
			},
			handleSizeChange(val) {
				this.limit = val;
				this.colonel();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.colonel();
			},
			// 用户分析选择日期
			animeDat() {
				this._getData()
			},
			// 订单分析选择日期
			animeOrder() {
				this.taskData()
				this.colonel()
				this.taskDataD()
				this.taskDataZf()
				this.taskDataYj()
			},
			// 订单分析年月日
			orderfenxi(value) {
				let vanumber = value
				if (vanumber === 1) {
					this.flag = 1
					this.taskData()
					this.colonel()
					this.taskDataD()
					this.taskDataZf()
					this.taskDataUser()
					this.taskDataYj()
				}
				if (vanumber === 2) {
					this.flag = 2
					this.taskData()
					this.colonel()
					this.taskDataD()
					this.taskDataZf()
					this.taskDataUser()
					this.taskDataYj()
				}
				if (vanumber === 3) {
					this.flag = 3
					this.taskData()
					this.colonel()
					this.taskDataD()
					this.taskDataZf()
					this.taskDataUser()
					this.taskDataYj()
				}
			},
			// Tabs点击事件
			handleClick(tab, event) {
				if (tab._props.label == '服务分析') {
					this.taskData()
				}
				if (tab._props.label == '服务收入分析') {
					this.colonel()
				}
				if (tab._props.label == '订单分析') {
					this.taskDataD()
				}
				if (tab._props.label == '订单统计(总)') {
					this.taskDataTj()
				}
				if (tab._props.label == '支付统计(总)') {
					this.taskDataZf()
				}
				if (tab._props.label == '用户分析') {
					this.taskDataUser()
				}
				if (tab._props.label == '平台佣金统计') {
					this.taskDataYj()
				}
				if (tab._props.label == '待处理事项') {
					this.chatNumTs = this.$cookie.get('numberTs')
					this.chatNumQy = this.$cookie.get('numberDrz')
					this.chatNumSf = this.$cookie.get('numberDts')
					this.chatNumPj = this.$cookie.get('numberDsh')
					this.chatNumFw = this.$cookie.get('numberDfw')
					// this.dataSelect()
					// this.dataSelectPy()
					// this.dataSelectJjqz()
					// this.dataSelectYh()
				}
				
				
				
			},
			//处理默认选中当前日期
			getNowTime() {
				var now = new Date();
				var year = now.getFullYear(); //得到年份
				var month = now.getMonth(); //得到月份
				var date = now.getDate(); //得到日期
				var hh = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
				var mm = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
				var ss = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
				month = month + 1;
				month = month.toString().padStart(2, "0");
				date = date.toString().padStart(2, "0");
				var defaultDate = `${year}-${month}-${date}`;
				return defaultDate;
				this.$set(this.info, "stockDate", defaultDate);
			},
			// 用户统计
			_getData() {
				this.$http({
					url: this.$http.adornUrl('user/homeMessage'),
					method: 'get',
					params: this.$http.adornParams({})
				}).then(({
					data
				}) => {
					if (data.code == 0) {
						let returnData = data.data;
						this.tableData = returnData
					}

				})
			},
			// 收入统计
			//  incomeSelect(){
			//  this.$http({
			// url: this.$http.adornUrl('statistical/income'),
			// method: 'get',
			// params: this.$http.adornParams({
			// })
			//  }).then(({data}) => {
			//   if(data.code == 0){
			// 	  let returnData = data.data;
			// 	  this.incomeData = returnData
			//   }
			//  })
			//  },
			//服务分析
			taskData() {
				this.$http({
					url: this.$http.adornUrl('orderTaking/taskAnalysis'),
					method: 'get',
					params: this.$http.adornParams({
						'time': this.info.stockDate,
						'flag': this.flag,
					})
				}).then(({
					data
				}) => {
					let returnData = data.data;
					this.taskStatF = returnData
				})
			},
			// 服务收入分析
			colonel() {
				this.tableDataLoading = true
				this.$http({
					url: this.$http.adornUrl('orderTaking/incomeAnalysis'),
					method: 'get',
					params: this.$http.adornParams({
						'page': this.page,
						'limit': this.limit,
						'time': this.info.stockDate,
						'flag': this.flag,
					})
				}).then(({
					data
				}) => {
					this.tableDataLoading = false
					if (data.code == 0) {
						let returnData = data.data
						this.tableData2 = returnData
					}
				})
			},
			// 订单分析
			taskDataD() {
				this.$http({
					url: this.$http.adornUrl('orders/selectOrdersStatistics'),
					method: 'get',
					params: this.$http.adornParams({
						'time': this.info.stockDate,
						'flag': this.flag,
					})
				}).then(({
					data
				}) => {
					let returnData = data.data;
					this.taskStat = returnData
				})
			},
			// 订单总统计
			taskDataTj(){
				this.$http({
					url: this.$http.adornUrl('orders/selectOrdersToTal'),
					method: 'get',
					params: this.$http.adornParams({
					})
				}).then(({
					data
				}) => {
					let returnData = data.data;
					this.taskStatTj = returnData
				})
			},
			// 支付统计 
			taskDataZf() {
				this.$http({
					url: this.$http.adornUrl('orders/selectOrdersPayStatistics'),
					method: 'get',
					params: this.$http.adornParams({
						'time': this.info.stockDate,
						'flag': this.flag,
					})
				}).then(({
					data
				}) => {
					let returnData = data.data;
					this.taskStatZf = returnData
				})
			},
			//用户分析
			taskDataUser() {
				this.$http({
					url: this.$http.adornUrl('user/getUserStatistics'),
					method: 'get',
					params: this.$http.adornParams({
						'time': this.info.stockDate,
						'flag': this.flag,
					})
				}).then(({
					data
				}) => {
					let returnData = data.data;
					this.taskStatUser = returnData
				})
			},
			// 平台佣金统计
			taskDataYj(){
				this.$http({
					url: this.$http.adornUrl('cash/commissionCensus'),
					method: 'get',
					params: this.$http.adornParams({
						'time': this.info.stockDate,
						'flag': this.flag,
					})
				}).then(({
					data
				}) => {
					let returnData = data.data;
					this.taskStatYj = returnData
				})
			},
			updatesD1() {
				this.$router.push({
					path: '/message',
					query: {
					}
				})
			},
			updatesD2() {
				this.$router.push({
					path: '/autonymPeople',
					query: {
						status: 1
					}
				})
			},
			updatesD3() {
				this.$router.push({
					path: '/autonym',
					query: {
						status: 1
					}
				})
			},
			updatesD4() {
				this.$router.push({
					path: '/comment',
					query: {
						status: 1
					}
				})
			},
			updatesD5(){
				this.$router.push({
					path: '/locality',
					query: {
						status: 1
					}
				})
			},
		}
	}
</script>

<style scoped>
	.box {
		padding: 44px;
		border: 1px solid #eee;
		margin: 15px 10px;
	}

	.box_num {
		font-size: 14px;
		color: #66b1ff;
	}

	.box_num .box_color {
		color: #333;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.box_num div span {
		font-size: 20px;
		margin-left: 5px;
	}

	.text_color {
		color: #4f9dec;
	}

	.text_color span {
		margin-right: 5px;
	}
</style>

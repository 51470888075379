var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "inline-block",
            "font-size": "18px",
            "margin-bottom": "15px",
          },
        },
        [
          _c(
            "a",
            {
              staticStyle: { "text-decoration": "none", "font-size": "14px" },
              attrs: { href: "#" },
              on: { click: _vm.prev },
            },
            [
              _c("icon-svg", {
                staticStyle: {
                  width: "1.2em",
                  height: "1.2em",
                  position: "relative",
                  top: "0.3em",
                },
                attrs: { name: "jiantou" },
              }),
              _vm._v(" 返回 "),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                margin: "0 15px",
                color: "#D9D9D9",
              },
            },
            [_vm._v("|")]
          ),
          _c("span", [_vm._v(_vm._s(_vm.titles))]),
        ]
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "span",
            {
              staticStyle: {
                width: "200px",
                display: "inline-block",
                "text-align": "right",
              },
            },
            [_vm._v("服务类型：")]
          ),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.goodsType,
                callback: function ($$v) {
                  _vm.goodsType = $$v
                },
                expression: "goodsType",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("普通服务")]),
              _c("el-radio", { attrs: { label: 2 } }, [_vm._v("商铺服务")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.goodsType == 2
        ? _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("商铺：")]
              ),
              _c("el-input", {
                staticStyle: { width: "45%" },
                attrs: { placeholder: "请选择商铺" },
                on: { focus: _vm.shangpu },
                model: {
                  value: _vm.shangpuName,
                  callback: function ($$v) {
                    _vm.shangpuName = $$v
                  },
                  expression: "shangpuName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { attrs: { title: _vm.titles, center: "" } }, [
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("服务标题：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: { placeholder: "请输入服务标题" },
              model: {
                value: _vm.goodsTitle,
                callback: function ($$v) {
                  _vm.goodsTitle = $$v
                },
                expression: "goodsTitle",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px", display: "flex" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("封面图：")]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  width: "148px",
                  height: "148px",
                  border: "1px dashed #c0ccda",
                  "border-radius": "6px",
                  "text-align": "center",
                  "line-height": "148px",
                },
              },
              [
                _c(
                  "custom-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      action: _vm.$http.adornUrl("oss/upload"),
                      "show-file-list": false,
                      "on-success": _vm.handleAvatarSuccess,
                    },
                    model: {
                      value: _vm.titleImg,
                      callback: function ($$v) {
                        _vm.titleImg = $$v
                      },
                      expression: "titleImg",
                    },
                  },
                  [
                    _vm.titleImg
                      ? _c("img", {
                          staticClass: "avatar",
                          staticStyle: {
                            "border-radius": "6px",
                            width: "148px",
                            height: "148px",
                          },
                          attrs: { src: _vm.titleImg },
                        })
                      : _c("i", {
                          staticClass:
                            "el-icon-plus avatar-uploader-icon iconss",
                        }),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px", display: "flex" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("轮播图：")]
            ),
            _vm._l(_vm.img, function (item, index) {
              return _c("div", { key: index, staticClass: "imgs" }, [
                _c("img", {
                  staticClass: "images",
                  attrs: { width: "100%", height: "100%", src: item, alt: "" },
                }),
                _c("span", { staticClass: "dels" }, [
                  _c("i", {
                    staticClass: "el-icon-delete",
                    on: {
                      click: function ($event) {
                        return _vm.dels(index)
                      },
                    },
                  }),
                ]),
              ])
            }),
            _c(
              "div",
              { staticClass: "imgs", staticStyle: { width: "50%" } },
              [
                _c(
                  "custom-upload",
                  {
                    attrs: {
                      action: _vm.$http.adornUrl("oss/upload"),
                      "list-type": "picture-card",
                      "show-file-list": false,
                      "on-success": _vm.handleRemove,
                    },
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _c(
                  "el-dialog",
                  {
                    attrs: { visible: _vm.dialogVisible },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisible = $event
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "100%",
                        src: _vm.dialogImageUrl,
                        alt: "",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm.goodsType == 2
          ? _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      width: "200px",
                      display: "inline-block",
                      "text-align": "right",
                    },
                  },
                  [_vm._v("商家返利金额：")]
                ),
                _c("el-input", {
                  staticStyle: { width: "45%" },
                  attrs: {
                    placeholder: "请输入返现积分",
                    onkeyup:
                      "value=value.replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
                    type: "number",
                    min: "0",
                  },
                  model: {
                    value: _vm.merchantMoney,
                    callback: function ($$v) {
                      _vm.merchantMoney = $$v
                    },
                    expression: "merchantMoney",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("会员满返：")]
            ),
            _c("span", [_vm._v("满 ")]),
            _c("el-input", {
              staticStyle: { width: "200px" },
              attrs: {
                placeholder: "请输入金额要求",
                onkeyup:
                  "value=value.replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
                type: "number",
                min: "0",
              },
              model: {
                value: _vm.memberPrice,
                callback: function ($$v) {
                  _vm.memberPrice = $$v
                },
                expression: "memberPrice",
              },
            }),
            _c("span", [_vm._v("返 ")]),
            _c("el-input", {
              staticStyle: { width: "200px" },
              attrs: {
                placeholder: "请输入优惠金额",
                onkeyup:
                  "value=value.replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
                type: "number",
                min: "0",
              },
              model: {
                value: _vm.memberMoney,
                callback: function ($$v) {
                  _vm.memberMoney = $$v
                },
                expression: "memberMoney",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("普通用户满返：")]
            ),
            _c("span", [_vm._v("满 ")]),
            _c("el-input", {
              staticStyle: { width: "200px" },
              attrs: {
                placeholder: "请输入金额要求",
                onkeyup:
                  "value=value.replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
                type: "number",
                min: "0",
              },
              model: {
                value: _vm.price,
                callback: function ($$v) {
                  _vm.price = $$v
                },
                expression: "price",
              },
            }),
            _c("span", [_vm._v("返 ")]),
            _c("el-input", {
              staticStyle: { width: "200px" },
              attrs: {
                placeholder: "请输入优惠金额",
                onkeyup:
                  "value=value.replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
                type: "number",
                min: "0",
              },
              model: {
                value: _vm.money,
                callback: function ($$v) {
                  _vm.money = $$v
                },
                expression: "money",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("服务品类：")]
            ),
            _c(
              "el-select",
              {
                staticStyle: { width: "45%" },
                on: { change: _vm.onChang },
                model: {
                  value: _vm.fromType,
                  callback: function ($$v) {
                    _vm.fromType = $$v
                  },
                  expression: "fromType",
                },
              },
              _vm._l(_vm.cities, function (item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("小程序跳转链接：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: { placeholder: "请输入小程序跳转链接" },
              model: {
                value: _vm.url,
                callback: function ($$v) {
                  _vm.url = $$v
                },
                expression: "url",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("服务分类：")]
            ),
            _c(
              "el-checkbox-group",
              {
                staticStyle: { display: "initial" },
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.fromTypes, function (item, index) {
                return _c(
                  "span",
                  { key: index, staticStyle: { "margin-right": "20px" } },
                  [
                    item.state == 1
                      ? _c("el-checkbox", { attrs: { label: item.id } }, [
                          _vm._v(_vm._s(item.classifyName) + " "),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("评星数量：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: {
                placeholder: "请输入评星数量",
                onkeyup: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                type: "number",
                min: "0",
              },
              model: {
                value: _vm.numStar,
                callback: function ($$v) {
                  _vm.numStar = $$v
                },
                expression: "numStar",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("上传图片数量：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: {
                placeholder: "请输入上传图片数量",
                onkeyup: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                type: "number",
                min: "0",
              },
              model: {
                value: _vm.numImg,
                callback: function ($$v) {
                  _vm.numImg = $$v
                },
                expression: "numImg",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("评论字数：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: {
                placeholder: "请输入评论字数",
                onkeyup: "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                type: "number",
                min: "0",
              },
              model: {
                value: _vm.numWord,
                callback: function ($$v) {
                  _vm.numWord = $$v
                },
                expression: "numWord",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("备注：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%", display: "none" },
              attrs: { placeholder: "请输入备注" },
              model: {
                value: _vm.remark,
                callback: function ($$v) {
                  _vm.remark = $$v
                },
                expression: "remark",
              },
            }),
            _c("quill-editor", {
              ref: "myTextEditor",
              staticStyle: {
                "padding-bottom": "50px",
                height: "300px",
                width: "72%",
                display: "inline-table",
                "margin-bottom": "60px",
              },
              attrs: { options: _vm.quillOption },
              model: {
                value: _vm.remark,
                callback: function ($$v) {
                  _vm.remark = $$v
                },
                expression: "remark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("服务平台：")]
            ),
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.classify,
                  callback: function ($$v) {
                    _vm.classify = $$v
                  },
                  expression: "classify",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("饿了么")]),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("美团")]),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
          _c(
            "span",
            {
              staticStyle: {
                width: "200px",
                display: "inline-block",
                "text-align": "right",
              },
            },
            [_vm._v("起止时间：")]
          ),
          _c(
            "span",
            { staticClass: "block" },
            [
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "default-time": ["09:00:00", "23:59:59"],
                },
                on: { change: _vm.changeaa },
                model: {
                  value: _vm.value1,
                  callback: function ($$v) {
                    _vm.value1 = $$v
                  },
                  expression: "value1",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("接单数量：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: {
                placeholder: "请输入接单数量",
                onkeyup:
                  "value=value.replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
                type: "number",
                min: "0",
              },
              model: {
                value: _vm.sumNum,
                callback: function ($$v) {
                  _vm.sumNum = $$v
                },
                expression: "sumNum",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("城市：")]
            ),
            _c("el-cascader", {
              ref: "cascaderAddr",
              staticStyle: { width: "45%" },
              attrs: {
                size: "large",
                options: _vm.options,
                placeholder: _vm.storeAddre,
                value: _vm.storeAddre,
              },
              on: { change: _vm.handleChange55 },
              model: {
                value: _vm.storeAddress,
                callback: function ($$v) {
                  _vm.storeAddress = $$v
                },
                expression: "storeAddress",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("地址：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: { placeholder: "请输入详细地址" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.select.apply(null, arguments)
                },
              },
              model: {
                value: _vm.address,
                callback: function ($$v) {
                  _vm.address = $$v
                },
                expression: "address",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("纬度：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: { placeholder: "请输入纬度" },
              model: {
                value: _vm.latitude,
                callback: function ($$v) {
                  _vm.latitude = $$v
                },
                expression: "latitude",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("经度：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: { placeholder: "请输入经度" },
              model: {
                value: _vm.longitude,
                callback: function ($$v) {
                  _vm.longitude = $$v
                },
                expression: "longitude",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("配送范围(单位：米)：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: {
                placeholder: "请输入配送范围",
                type: "number",
                min: "0",
              },
              model: {
                value: _vm.scope,
                callback: function ($$v) {
                  _vm.scope = $$v
                },
                expression: "scope",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("是否渠道用户：")]
            ),
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.isChannel,
                  callback: function ($$v) {
                    _vm.isChannel = $$v
                  },
                  expression: "isChannel",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
              ],
              1
            ),
          ],
          1
        ),
        _vm.isChannel == 1
          ? _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      width: "200px",
                      display: "inline-block",
                      "text-align": "right",
                    },
                  },
                  [_vm._v("渠道用户：")]
                ),
                _c("el-input", {
                  staticStyle: { width: "45%" },
                  attrs: { placeholder: "请选择用户" },
                  on: { focus: _vm.userselect },
                  model: {
                    value: _vm.nickName,
                    callback: function ($$v) {
                      _vm.nickName = $$v
                    },
                    expression: "nickName",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.isChannel == 1
          ? _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      width: "200px",
                      display: "inline-block",
                      "text-align": "right",
                    },
                  },
                  [_vm._v("渠道佣金：")]
                ),
                _c("el-input", {
                  staticStyle: { width: "45%" },
                  attrs: {
                    placeholder: "请输入渠道佣金",
                    onkeyup:
                      "value=value.replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
                  },
                  model: {
                    value: _vm.channelMoney,
                    callback: function ($$v) {
                      _vm.channelMoney = $$v
                    },
                    expression: "channelMoney",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("返现金额：")]
            ),
            _c("el-input", {
              staticStyle: { width: "45%" },
              attrs: {
                placeholder: "请输入返现积分",
                onkeyup:
                  "value=value.replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
              },
              model: {
                value: _vm.returnMoney,
                callback: function ($$v) {
                  _vm.returnMoney = $$v
                },
                expression: "returnMoney",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("级别：")]
            ),
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.isGoods,
                  callback: function ($$v) {
                    _vm.isGoods = $$v
                  },
                  expression: "isGoods",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("精选")]),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("普通")]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "span",
              {
                staticStyle: {
                  width: "200px",
                  display: "inline-block",
                  "text-align": "right",
                },
              },
              [_vm._v("状态：")]
            ),
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.status,
                  callback: function ($$v) {
                    _vm.status = $$v
                  },
                  expression: "status",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("上架")]),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("下架")]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            staticStyle: { "margin-top": "30px", "text-align": "center" },
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c("el-button", { on: { click: _vm.prev } }, [_vm._v("取 消")]),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.addmissionNoticeTo()
                  },
                },
              },
              [_vm._v("保 存")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "用户列表",
            visible: _vm.dialogFormVisible3,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible3 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "2% 0", display: "inline-block" } },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { clearable: "", placeholder: "请输入手机号" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.userclick.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone",
                },
              }),
              _vm._v("     "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.userclick },
                },
                [_vm._v(" 查询 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.userclose },
                },
                [_vm._v(" 重置 ")]
              ),
            ],
            1
          ),
          _vm.userIds
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading4,
                      expression: "tableDataLoading4",
                    },
                  ],
                  attrs: { width: "780px", data: _vm.userIds.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "channelName",
                      width: "220",
                      label: "渠道商",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                { staticStyle: { color: "#f56c6c" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.channelName
                                        ? scope.row.channelName
                                        : "未设置"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      13480311
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", width: "220", label: "手机号" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.phone ? scope.row.phone : "未绑定"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      523075458
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      width: "220",
                      label: "创建时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right", width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirm(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("确定 ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      277798038
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.userIds
            ? _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [5, 10, 15, 20],
                      "page-size": _vm.size,
                      "current-page": _vm.pages,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.userIds.totalCount,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange2,
                      "current-change": _vm.handleCurrentChange2,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商铺列表",
            visible: _vm.dialogFormVisible5,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible5 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "inline-block" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                  },
                },
                [
                  _c("span", [_vm._v("商铺名：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入申请商铺名" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select1.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.merchantName,
                      callback: function ($$v) {
                        _vm.merchantName = $$v
                      },
                      expression: "merchantName",
                    },
                  }),
                  _vm._v("   "),
                  _c("span", [_vm._v("电话：")]),
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入申请电话" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select1.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.merchantPhone,
                      callback: function ($$v) {
                        _vm.merchantPhone = $$v
                      },
                      expression: "merchantPhone",
                    },
                  }),
                  _vm._v("   "),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.select1 },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { size: "mini", type: "primary", icon: "document" },
                  on: { click: _vm.cleans },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.renwuData.records },
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "merchantId",
                  label: "编号",
                  align: "center",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "nickName", label: "渠道商" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4f9dec",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.nickName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "merchantTypeName", label: "商铺类型" },
              }),
              _c("el-table-column", {
                attrs: { prop: "merchantName", label: "商铺名称" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "merchantLogo",
                  label: "商铺logo",
                  width: "180",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.merchantLogo
                          ? _c("img", {
                              staticStyle: { "border-radius": "50%" },
                              attrs: {
                                src: scope.row.merchantLogo,
                                alt: "",
                                width: "80",
                                height: "80",
                              },
                            })
                          : _c("span", [_vm._v("暂无")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "merchantPhone", label: "电话" },
              }),
              _c("el-table-column", {
                attrs: { prop: "merchantAddress", label: "地址", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.merchantProvince) +
                              _vm._s(scope.row.merchantCity) +
                              _vm._s(scope.row.merchantDistrict) +
                              _vm._s(scope.row.merchantAddress)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "isGoods", label: "状态", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 1
                          ? _c("span", [_vm._v("待审核")])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("span", [_vm._v("已上架")])
                          : _vm._e(),
                        scope.row.status == 3
                          ? _c("span", [_vm._v("拒绝")])
                          : _vm._e(),
                        scope.row.status == 4
                          ? _c("span", [_vm._v("下架")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  width: "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.queren(scope.row)
                              },
                            },
                          },
                          [_vm._v("确认")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.renwuData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", {
        staticStyle: { width: "80%", height: "500px", "margin-left": "10%" },
        attrs: { id: "container1" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
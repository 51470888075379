var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "font-size": "18px", "margin-bottom": "15px" } },
        [
          _c(
            "a",
            {
              staticStyle: { "text-decoration": "none", "font-size": "14px" },
              attrs: { href: "#" },
              on: { click: _vm.prev },
            },
            [
              _c("icon-svg", {
                staticStyle: {
                  width: "1.2em",
                  height: "1.2em",
                  position: "relative",
                  top: "0.3em",
                },
                attrs: { name: "jiantou" },
              }),
              _vm._v(" 返回 "),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                margin: "0 15px",
                color: "#D9D9D9",
              },
            },
            [_vm._v("|")]
          ),
          _c("span", [_vm._v("任务订单列表")]),
        ]
      ),
      _c(
        "div",
        { staticStyle: { display: "inline-block" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                display: "inline-block",
                margin: "10px 0px",
              },
            },
            [
              _c("span", [_vm._v("状态：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px", "margin-left": "10px" },
                  on: {
                    change: function ($event) {
                      return _vm.animeDat2(_vm.status)
                    },
                  },
                  model: {
                    value: _vm.status,
                    callback: function ($$v) {
                      _vm.status = $$v
                    },
                    expression: "status",
                  },
                },
                _vm._l(_vm.taskstates, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v("    "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "inline-block", margin: "3px" } },
            [
              _c("span", [_vm._v("服务类型：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px", "margin-left": "10px" },
                  on: {
                    change: function ($event) {
                      return _vm.phoneSelect()
                    },
                  },
                  model: {
                    value: _vm.taskTypeT,
                    callback: function ($$v) {
                      _vm.taskTypeT = $$v
                    },
                    expression: "taskTypeT",
                  },
                },
                _vm._l(_vm.parentIdList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v("     "),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                display: "inline-block",
                margin: "10px 0px",
              },
            },
            [
              _c("span", [_vm._v("手机号:")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入手机号" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.phoneSelect.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone",
                },
              }),
              _vm._v("   "),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                display: "inline-block",
                margin: "3px",
              },
            },
            [
              _c("span", [_vm._v("骑手电话：")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入骑手电话" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.phoneSelect.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.riderPhone,
                  callback: function ($$v) {
                    _vm.riderPhone = $$v
                  },
                  expression: "riderPhone",
                },
              }),
              _vm._v("   "),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                position: "relative",
                display: "inline-block",
                margin: "10px 0px",
              },
            },
            [
              _c("span", [_vm._v("昵称:")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入昵称" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.phoneSelect.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.userName,
                  callback: function ($$v) {
                    _vm.userName = $$v
                  },
                  expression: "userName",
                },
              }),
              _vm._v("   "),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.phoneSelect },
            },
            [_vm._v("查询 ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { size: "mini", type: "primary", icon: "document" },
              on: { click: _vm.cleans2 },
            },
            [_vm._v("重置 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          attrs: { data: _vm.tableData2.list },
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              prop: "helpOrderId",
              label: "编号",
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "",
              prop: "userName",
              label: "发单用户",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#4f9dec", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.updates(scope.row.userId)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.userName ? scope.row.userName : "未绑定"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "",
              prop: "userName",
              label: "接单员工",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#4f9dec", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.updates(scope.row.companyId)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.rideName ? scope.row.rideName : "-"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "taskType", label: "服务类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.taskType == 1
                      ? _c("span", [_vm._v("悬赏任务")])
                      : _vm._e(),
                    scope.row.taskType == 2
                      ? _c("span", [_vm._v("报价任务")])
                      : _vm._e(),
                    scope.row.taskType == 3
                      ? _c("span", [_vm._v("一口价任务")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "下单人姓名", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { prop: "phone", label: "下单人手机号", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { prop: "province", label: "下单人地址", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.province) +
                          _vm._s(scope.row.city) +
                          _vm._s(scope.row.district) +
                          _vm._s(scope.row.detailsAddress)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "提交内容" },
          }),
          _c("el-table-column", {
            attrs: { prop: "money", label: "接单价格", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { prop: "startTime", label: "服务开始时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "endTime", label: "服务结束时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "startTaskAddress", label: "服务开始地址" },
          }),
          _c("el-table-column", {
            attrs: { prop: "endTaskAddress", label: "服务结束地址" },
          }),
          _c("el-table-column", {
            attrs: { prop: "startTime", label: "服务开始时间" },
          }),
          _c("el-table-column", {
            attrs: { prop: "endTime", label: "服务结束时间" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "startTaskImg",
              label: "服务开始前图片",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.startTaskImg
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                            },
                          },
                          _vm._l(
                            scope.row.startTaskImg.split(","),
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top-start",
                                        title: "",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "50px",
                                          height: "50px",
                                          margin: "3px",
                                        },
                                        attrs: {
                                          slot: "reference",
                                          src: item,
                                          alt: "",
                                        },
                                        slot: "reference",
                                      }),
                                      _c("img", {
                                        staticStyle: {
                                          width: "300px",
                                          height: "auto",
                                        },
                                        attrs: { src: item, alt: "" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "endTaskImg",
              label: "服务结束后图片",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.endTaskImg
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                            },
                          },
                          _vm._l(
                            scope.row.endTaskImg.split(","),
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top-start",
                                        title: "",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "50px",
                                          height: "50px",
                                          margin: "3px",
                                        },
                                        attrs: {
                                          slot: "reference",
                                          src: item,
                                          alt: "",
                                        },
                                        slot: "reference",
                                      }),
                                      _c("img", {
                                        staticStyle: {
                                          width: "300px",
                                          height: "auto",
                                        },
                                        attrs: { src: item, alt: "" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "startAddress",
              label: "服务开始地址",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "endAddress", label: "服务结束地址", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { prop: "startTaskLongitude", label: "服务开始经度" },
          }),
          _c("el-table-column", {
            attrs: { prop: "startTaskLatitude", label: "服务开始纬度" },
          }),
          _c("el-table-column", {
            attrs: { prop: "endTaskLongitude", label: "服务结束经度" },
          }),
          _c("el-table-column", {
            attrs: { prop: "endTaskLatitude", label: "服务结束纬度" },
          }),
          _c("el-table-column", { attrs: { prop: "code", label: "确认码" } }),
          _c("el-table-column", {
            attrs: {
              prop: "serviceTime",
              label: "预约时间",
              fixed: "right",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      { staticStyle: { color: "red", "font-weight": "600" } },
                      [_vm._v(_vm._s(scope.row.deliveryTime))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "5px" },
                        attrs: { size: "mini", type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.updateBz(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "remarks", label: "订单备注", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.remarks ? scope.row.remarks : "未填写")
                      ),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "5px" },
                        attrs: { size: "mini", type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.updateBz(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              prop: "status",
              label: "状态",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === 1
                      ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                          _vm._v("待服务"),
                        ])
                      : _vm._e(),
                    scope.row.status === 2
                      ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                          _vm._v("服务中"),
                        ])
                      : _vm._e(),
                    scope.row.status === 3
                      ? _c("span", { staticStyle: { color: "#4f9dec" } }, [
                          _vm._v("已完成"),
                        ])
                      : _vm._e(),
                    scope.row.status === 4
                      ? _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v("已取消"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: "操作",
              width: "100",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("shopsList:paidan"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.zuandanBtn(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 派单")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.totalMoney
        ? _c(
            "div",
            {
              staticStyle: {
                color: "#B94A48",
                "font-size": "20px",
                "margin-top": "10px",
              },
            },
            [_vm._v("本页累计交易统计：" + _vm._s(_vm.totalMoney) + "元")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { "text-align": "center", "margin-top": "10px" } },
        [
          _c("el-pagination", {
            attrs: {
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.limit,
              "current-page": _vm.page,
              layout: "total,sizes, prev, pager, next",
              total: _vm.tableData2.totalCount,
            },
            on: {
              "size-change": _vm.handleSizeChange3,
              "current-change": _vm.handleCurrentChange3,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "聊天内容",
            visible: _vm.dialogFormVisible,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading1,
                  expression: "tableDataLoading1",
                },
              ],
              attrs: { data: _vm.userData.list },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "chatContentId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "用户昵称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.shopId
                          ? _c("div", [
                              _vm._v(" " + _vm._s(scope.row.shopName) + " "),
                            ])
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.details(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.userName
                                        ? scope.row.userName
                                        : scope.row.riderName
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "用户头像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  title: "",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    src: scope.row.shopCover
                                      ? scope.row.shopCover
                                      : scope.row.riderAvatar
                                      ? scope.row.riderAvatar
                                      : scope.row.userAvatar,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c("img", {
                                  staticStyle: {
                                    width: "200px",
                                    height: "200px",
                                  },
                                  attrs: {
                                    src: scope.row.shopCover
                                      ? scope.row.shopCover
                                      : scope.row.riderAvatar
                                      ? scope.row.riderAvatar
                                      : scope.row.userAvatar,
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "信息内容", width: "280" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.messageType == 1
                          ? _c("div", [_vm._v(_vm._s(scope.row.content))])
                          : _vm._e(),
                        scope.row.messageType == 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        src: scope.row.content,
                                        alt: "",
                                      },
                                      slot: "reference",
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                      },
                                      attrs: {
                                        src: scope.row.content,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.messageType == 3
                          ? _c("div", [
                              _c("audio", {
                                attrs: {
                                  src: scope.row.content,
                                  type: "audio/wav",
                                  controls: "controls",
                                },
                              }),
                            ])
                          : _vm._e(),
                        scope.row.messageType == 4
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      title: "",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "50px",
                                        height: "50px",
                                      },
                                      attrs: {
                                        slot: "reference",
                                        src:
                                          "https://cdc.cq4oss.ctyunxs.cn/ygzw/" +
                                          scope.row.content,
                                        alt: "",
                                      },
                                      slot: "reference",
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "200px",
                                      },
                                      attrs: {
                                        src:
                                          "https://cdc.cq4oss.ctyunxs.cn/ygzw/" +
                                          scope.row.content,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "150" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "操作",
                  width: "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("userList:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteuser(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 50, 100],
                  "page-size": _vm.limit1,
                  "current-page": _vm.page1,
                  layout: "total,sizes, prev, pager, next",
                  total: _vm.userData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange1,
                  "current-change": _vm.handleCurrentChange1,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "员工列表",
            visible: _vm.dialogFormVisibleHd,
            center: "",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleHd = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoadingY,
                  expression: "tableDataLoadingY",
                },
              ],
              attrs: { data: _vm.huodongData.list },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "userId", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "用户名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#f56c6c",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.row.userId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.userName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "avatar", label: "用户头像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.avatar == null
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/img/avatar.png"),
                                alt: "",
                                width: "40",
                                height: "40",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: scope.row.avatar,
                                alt: "",
                                width: "40",
                                height: "40",
                              },
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "电话" },
              }),
              _c("el-table-column", {
                attrs: { prop: "updateTime", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  prop: "status",
                  label: "操作",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.querenBtns(scope.row)
                              },
                            },
                          },
                          [_vm._v("确认派单")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 15, 20, 25],
                  "page-size": _vm.limitH,
                  "current-page": _vm.pageH,
                  layout: "total,sizes, prev, pager, next",
                  total: _vm.huodongData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改备注时间",
            visible: _vm.dialogFormVisibleBz,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleBz = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("预约时间：")]
              ),
              _c("el-date-picker", {
                staticStyle: { width: "50%" },
                attrs: {
                  align: "right",
                  type: "datetime",
                  format: "yyyy-MM-dd HH:mm",
                  "value-format": "yyyy-MM-dd HH:mm",
                  placeholder: "选择开始时间",
                },
                model: {
                  value: _vm.serveTime,
                  callback: function ($$v) {
                    _vm.serveTime = $$v
                  },
                  expression: "serveTime",
                },
              }),
              _vm._v("    "),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                    position: "relative",
                    top: "-70px",
                  },
                },
                [_vm._v("备注：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: { type: "textarea", rows: 4, placeholder: "请输入备注" },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisibleBz = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.replyNoticeTo()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "服务列表", name: "third" } },
            [
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        display: "inline-block",
                      },
                    },
                    [
                      _c("span", [_vm._v("是否推荐：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-left": "10px",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.animeDat4(_vm.classify)
                            },
                          },
                          model: {
                            value: _vm.isRecommendT,
                            callback: function ($$v) {
                              _vm.isRecommendT = $$v
                            },
                            expression: "isRecommendT",
                          },
                        },
                        _vm._l(_vm.classifyList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                      _vm._v("    "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        display: "inline-block",
                      },
                    },
                    [
                      _c("span", [_vm._v("服务分类：")]),
                      _c("el-cascader", {
                        staticStyle: { width: "150px" },
                        attrs: {
                          options: _vm.homeData,
                          "show-all-levels": false,
                          props: {
                            expandTrigger: "hover",
                            checkStrictly: "true",
                            value: "id",
                            label: "gameName",
                          },
                        },
                        on: { change: _vm.animeDat2 },
                        model: {
                          value: _vm.classifyId,
                          callback: function ($$v) {
                            _vm.classifyId = $$v
                          },
                          expression: "classifyId",
                        },
                      }),
                      _vm._v("     "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        display: "inline-block",
                      },
                    },
                    [
                      _c("span", [_vm._v("标题：")]),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入标题" },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.phoneSelect.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.titleT,
                          callback: function ($$v) {
                            _vm.titleT = $$v
                          },
                          expression: "titleT",
                        },
                      }),
                      _vm._v("   "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        display: "inline-block",
                      },
                    },
                    [
                      _c("span", [_vm._v("状态：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "150px",
                            "margin-left": "10px",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.animeDat3(_vm.status)
                            },
                          },
                          model: {
                            value: _vm.statusId,
                            callback: function ($$v) {
                              _vm.statusId = $$v
                            },
                            expression: "statusId",
                          },
                        },
                        _vm._l(_vm.statuss, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                      _vm._v("    "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        display: "inline-block",
                      },
                    },
                    [
                      _c("span", [_vm._v("服务地区:")]),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入服务地区" },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.phoneSelect.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.region1,
                          callback: function ($$v) {
                            _vm.region1 = $$v
                          },
                          expression: "region1",
                        },
                      }),
                      _vm._v("   "),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        display: "inline-block",
                      },
                    },
                    [
                      _c("span", [_vm._v("商家昵称：")]),
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入商家昵称" },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.phoneSelect.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                      _vm._v("   "),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.phoneSelect },
                    },
                    [_vm._v("查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.cleans2 },
                    },
                    [_vm._v("重置 ")]
                  ),
                  _vm._v("   "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "10px 0" },
                      attrs: {
                        disabled: !_vm.isAuth("locality:add"),
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.choiaddNotice()
                        },
                      },
                    },
                    [_vm._v("添加服务")]
                  ),
                  _vm._v("     "),
                ],
                1
              ),
              _c("div", {
                staticStyle: { float: "right", "margin-right": "2%" },
              }),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.choicenData.list, height: "600px" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "id",
                      label: "编号",
                      width: "80",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "isRecommend", label: "推荐", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-value": _vm.openValue1,
                                disabled: !_vm.isAuth("locality:update"),
                                "inactive-value": _vm.closeValue1,
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.change2(
                                    scope.row.isRecommend,
                                    scope.row
                                  )
                                },
                              },
                              model: {
                                value: scope.row.isRecommend,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isRecommend", $$v)
                                },
                                expression: "scope.row.isRecommend",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "userName", label: "商家昵称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", {
                              staticStyle: {
                                color: "#4f9dec",
                                cursor: "pointer",
                              },
                              domProps: {
                                textContent: _vm._s(scope.row.userName),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updatesvideo(scope.row)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "shopName", label: "店铺名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "homepageImg", label: "封面图" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.homepageImg && scope.row.homepageImg != ""
                              ? _c("img", {
                                  attrs: {
                                    src: scope.row.homepageImg,
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("span", [_vm._v("暂无图片")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "listImg", label: "列表图" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.listImg && scope.row.listImg != ""
                              ? _c("img", {
                                  attrs: {
                                    src: scope.row.listImg,
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("span", [_vm._v("暂无图片")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "homepageRecommendedImg",
                      label: "首页推荐图",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.homepageRecommendedImg &&
                            scope.row.homepageRecommendedImg != ""
                              ? _c("img", {
                                  attrs: {
                                    src: scope.row.homepageRecommendedImg,
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("span", [_vm._v("暂无图片")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "detailsImg",
                      label: "服务详情",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { margin: "10px 0" },
                                attrs: {
                                  size: "mini",
                                  plain: "",
                                  type: "primary",
                                  icon: "document",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.look(scope.row.detailsImg)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "myLevel", label: "标题", width: "150" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "gameName",
                      label: "服务分类",
                      width: "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "oldMoney", label: "发布价格" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "money", label: "普通用户价格" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "memberMoney", label: "会员价格" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "minNum", label: "最低服务时间" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "unit", label: "单位" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "salesNum", label: "销量" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.salesNum
                              ? _c("span", [_vm._v(_vm._s(scope.row.salesNum))])
                              : _c("span", [_vm._v("0")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              attrs: { size: "mini", min: 0 },
                              on: {
                                change: function ($event) {
                                  return _vm.sortBtn(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.sort,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "sort", $$v)
                                },
                                expression: "scope.row.sort",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "content", label: "审核说明", width: "300" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  title: "",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "-webkit-box",
                                      "-webkit-box-orient": "vertical",
                                      "-webkit-line-clamp": "3",
                                      overflow: "hidden",
                                      "max-height": "80px",
                                    },
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.content) + " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "400px",
                                      height: "auto",
                                      "word-break": "break-all",
                                      "max-height": "500px",
                                      overflow: "auto",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.content) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "money", label: "服务地区", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.region
                                    ? scope.row.region
                                    : "不限地区"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "count", label: "接单人数" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "orderScore", label: "评分" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "detailadd",
                      label: "详细地址",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.detailadd
                                    ? scope.row.detailadd
                                    : "暂无地址"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "longitude", label: "经度", width: "140" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "latitude", label: "纬度", width: "140" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "状态", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status === 1
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#4f9dec" } },
                                  [_vm._v("待审核")]
                                )
                              : _vm._e(),
                            scope.row.status == 0 || scope.row.status == 2
                              ? _c("el-switch", {
                                  attrs: {
                                    disabled: !_vm.isAuth("locality:update"),
                                    "active-value": _vm.openValue,
                                    "inactive-value": _vm.closeValue,
                                    "active-color": "#13ce66",
                                    "inactive-color": "#ff4949",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.change3(
                                        scope.row.state,
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.status,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "status", $$v)
                                    },
                                    expression: "scope.row.status",
                                  },
                                })
                              : _vm._e(),
                            scope.row.status === 3
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#4f9dec" } },
                                  [_vm._v("拒绝")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      prop: "id",
                      width: "160",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "grid",
                                  "grid-template-columns": "50% 50%",
                                  gap: "6px",
                                },
                              },
                              [
                                scope.row.status === 1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.shenhe(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("审核")]
                                    )
                                  : _vm._e(),
                                scope.row.status != 1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.plCompile(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("评论 ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      disabled: !_vm.isAuth("locality:update"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.choiCompile(
                                          scope.$index,
                                          scope.row,
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("修改 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.choiCompile(
                                          scope.$index,
                                          scope.row,
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "danger",
                                      disabled: !_vm.isAuth("locality:delete"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.choidelete(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    "margin-top": "10px",
                    float: "right",
                  },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 40],
                      "page-size": _vm.size,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.choicenData.totalCount,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange1,
                      "current-change": _vm.handleCurrentChange1,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.renwuTitles,
                    customClass: "customWidth",
                    visible: _vm.dialogFormVisible5,
                    center: "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogFormVisible5 = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("一级分类：")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "45%" },
                          on: { change: _vm.onChang3 },
                          model: {
                            value: _vm.gameNames,
                            callback: function ($$v) {
                              _vm.gameNames = $$v
                            },
                            expression: "gameNames",
                          },
                        },
                        _vm._l(_vm.homeData, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.gameName, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("二级分类：")]
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          staticStyle: { display: "inline" },
                          on: { change: _vm.onChang },
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.homeData2, function (item, index) {
                          return _c(
                            "el-checkbox",
                            { key: index, attrs: { label: item.gameName } },
                            [_vm._v(" " + _vm._s(item.gameName) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("商家昵称：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "45%" },
                        attrs: { placeholder: "请选择商家" },
                        on: { focus: _vm.userselect },
                        model: {
                          value: _vm.nickName1,
                          callback: function ($$v) {
                            _vm.nickName1 = $$v
                          },
                          expression: "nickName1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("标题：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "45%" },
                        attrs: { placeholder: "请输入标题" },
                        model: {
                          value: _vm.myLevel,
                          callback: function ($$v) {
                            _vm.myLevel = $$v
                          },
                          expression: "myLevel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { "margin-bottom": "10px", display: "flex" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("服务详情主页图(比例3:2)：")]
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("upload-picture-cropper", {
                            attrs: {
                              uploadWidth: 156,
                              fixedNumber: [3, 2],
                              imgSrc: _vm.homepageImg,
                              uploadHeight: 104,
                              objectFit: "cover",
                              actionUrl: _vm.$http.adornUrl("oss/upload"),
                            },
                            on: {
                              deleteCheng: _vm.deleteCheng2,
                              input: _vm.successCheng2,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { "margin-bottom": "10px", display: "flex" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("首页推荐图(比例 3:4或1:1)：")]
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("upload-picture-cropper", {
                            attrs: {
                              uploadWidth: 156,
                              fixed: false,
                              imgSrc: _vm.homepageRecommendedImg,
                              uploadHeight: 208,
                              objectFit: "cover",
                              actionUrl: _vm.$http.adornUrl("oss/upload"),
                            },
                            on: {
                              deleteCheng: _vm.deleteCheng,
                              input: _vm.successCheng,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { "margin-bottom": "10px", display: "flex" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("服务详情商品列表图(比例1:1)：")]
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("upload-picture-cropper", {
                            attrs: {
                              uploadWidth: 156,
                              imgSrc: _vm.listImg,
                              fixedNumber: [1, 1],
                              uploadHeight: 156,
                              objectFit: "cover",
                              actionUrl: _vm.$http.adornUrl("oss/upload"),
                            },
                            on: {
                              input: _vm.successCheng1,
                              deleteCheng: _vm.deleteCheng1,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { "margin-bottom": "10px", display: "flex" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("服务详情：")]
                      ),
                      _c("quill-editor", {
                        ref: "myTextEditor",
                        staticStyle: {
                          "padding-bottom": "50px",
                          height: "300px",
                          width: "72%",
                          display: "inline-table",
                          "margin-bottom": "60px",
                        },
                        attrs: { options: _vm.quillOption },
                        model: {
                          value: _vm.detailsImg,
                          callback: function ($$v) {
                            _vm.detailsImg = $$v
                          },
                          expression: "detailsImg",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("发布价格：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "45%" },
                        attrs: {
                          type: "number",
                          min: "0",
                          placeholder: "请输入发布价格",
                        },
                        model: {
                          value: _vm.oldMoney,
                          callback: function ($$v) {
                            _vm.oldMoney = $$v
                          },
                          expression: "oldMoney",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("普通用户价格：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "45%" },
                        attrs: {
                          type: "number",
                          min: "0",
                          placeholder: "请输入普通用户价格",
                        },
                        model: {
                          value: _vm.money,
                          callback: function ($$v) {
                            _vm.money = $$v
                          },
                          expression: "money",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("会员价格：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "45%" },
                        attrs: {
                          type: "number",
                          min: "0",
                          placeholder: "请输入会员价格",
                        },
                        model: {
                          value: _vm.memberMoney,
                          callback: function ($$v) {
                            _vm.memberMoney = $$v
                          },
                          expression: "memberMoney",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("状态：")]
                      ),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.status,
                            callback: function ($$v) {
                              _vm.status = $$v
                            },
                            expression: "status",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("上架"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("下架"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("最低服务时间：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "45%" },
                        attrs: {
                          type: "number",
                          min: "1",
                          man: "10",
                          placeholder: "请输入最低服务时间",
                        },
                        model: {
                          value: _vm.minNum,
                          callback: function ($$v) {
                            _vm.minNum = $$v
                          },
                          expression: "minNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("单位：")]
                      ),
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.unit,
                            callback: function ($$v) {
                              _vm.unit = $$v
                            },
                            expression: "unit",
                          },
                        },
                        _vm._l(_vm.danwei, function (item, index) {
                          return _c(
                            "el-radio",
                            { key: index, attrs: { label: item } },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("排序：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "50%" },
                        attrs: {
                          type: "number",
                          min: "0",
                          placeholder: "请输入排序",
                        },
                        model: {
                          value: _vm.sort,
                          callback: function ($$v) {
                            _vm.sort = $$v
                          },
                          expression: "sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("服务地区：")]
                      ),
                      _c("el-cascader", {
                        ref: "cascaderAddr",
                        staticStyle: { width: "45%" },
                        attrs: {
                          size: "large",
                          options: _vm.options,
                          placeholder: _vm.storeAddre,
                          value: _vm.storeAddre,
                        },
                        on: { change: _vm.handleChange55 },
                        model: {
                          value: _vm.storeAddress,
                          callback: function ($$v) {
                            _vm.storeAddress = $$v
                          },
                          expression: "storeAddress",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("详细地址：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "45%" },
                        attrs: { placeholder: "请输入详细地址" },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.select.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.detailadd,
                          callback: function ($$v) {
                            _vm.detailadd = $$v
                          },
                          expression: "detailadd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("纬度：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "45%" },
                        attrs: { placeholder: "请输入纬度" },
                        model: {
                          value: _vm.latitude,
                          callback: function ($$v) {
                            _vm.latitude = $$v
                          },
                          expression: "latitude",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("经度：")]
                      ),
                      _c("el-input", {
                        staticStyle: { width: "45%" },
                        attrs: { placeholder: "请输入经度" },
                        model: {
                          value: _vm.longitude,
                          callback: function ($$v) {
                            _vm.longitude = $$v
                          },
                          expression: "longitude",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", [
                    _c("div", {
                      staticStyle: {
                        width: "80%",
                        height: "500px",
                        "margin-left": "10%",
                      },
                      attrs: { id: "container1" },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: {
                        "margin-top": "30px",
                        "text-align": "center",
                      },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c("el-button", { on: { click: _vm.prev } }, [
                        _vm._v("取 消"),
                      ]),
                      _vm.isModify
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.addmissionNoticeTo()
                                },
                              },
                            },
                            [_vm._v("确 定")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "服务分类", name: "first" } },
            [
              _c(
                "div",
                { staticStyle: { width: "100%", "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { margin: "10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                        disabled: !_vm.isAuth("locality:add"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.classAdd(0)
                        },
                      },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.tableData.records, "row-key": "id" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "id", label: "编号", width: "80" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "gameName", label: "服务名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "gameImg", label: "服务图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.gameImg && scope.row.gameImg != ""
                              ? _c("img", {
                                  attrs: {
                                    src: scope.row.gameImg,
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("span", [_vm._v("暂无图片")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sort", label: "排序" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                disabled: !_vm.isAuth("locality:update"),
                                "active-value": _vm.openValue2,
                                "inactive-value": _vm.closeValue2,
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.change(
                                    scope.row.id,
                                    scope.row.status
                                  )
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      prop: "id",
                      width: "240",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  disabled: !_vm.isAuth("locality:update"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.classAdd(scope.row)
                                  },
                                },
                              },
                              [_vm._v("修改 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  disabled: !_vm.isAuth("locality:delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.classdelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 40],
                      "page-size": _vm.limit,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "商家列表",
                visible: _vm.dialogFormVisible3,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible3 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("手机号：")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入手机号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.userclick.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.phone1,
                      callback: function ($$v) {
                        _vm.phone1 = $$v
                      },
                      expression: "phone1",
                    },
                  }),
                  _vm._v("     "),
                  _c("span", [_vm._v("昵称：")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入昵称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.userclick.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.nickName2,
                      callback: function ($$v) {
                        _vm.nickName2 = $$v
                      },
                      expression: "nickName2",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.userclick },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.userclose },
                    },
                    [_vm._v(" 重置 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading4,
                      expression: "tableDataLoading4",
                    },
                  ],
                  attrs: { width: "780px", data: _vm.userIds.records },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "nickName", label: "昵称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未设置"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "shopName", label: "商家名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "手机号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.phone ? scope.row.phone : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "createTime", label: "创建时间" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirm(scope.row)
                                  },
                                },
                              },
                              [_vm._v("选择 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [5, 10, 15, 20],
                      "page-size": _vm.size1,
                      "current-page": _vm.page1,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.userIds.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange2,
                      "current-change": _vm.handleCurrentChange2,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "图片",
                visible: _vm.dialogVisible,
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            _vm._l(_vm.imageUrl, function (item, index) {
              return _vm.imageUrl.length > 0
                ? _c("span", { key: index }, [
                    _c("img", {
                      staticStyle: {
                        width: "45%",
                        height: "100%",
                        margin: "15px",
                        display: "inline-block",
                      },
                      attrs: { src: item, alt: "" },
                    }),
                  ])
                : _c("span", [_vm._v("暂无图片")])
            }),
            0
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "服务审核",
                visible: _vm.dialogFormVisible8,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible8 = $event
                },
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  staticStyle: {
                    width: "100%",
                    "margin-bottom": "30px",
                    "margin-left": "200px",
                  },
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("通过")]),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("拒绝")]),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("审核理由：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      type: "textarea",
                      rows: 5,
                      placeholder: "请输入审核理由",
                    },
                    model: {
                      value: _vm.contents,
                      callback: function ($$v) {
                        _vm.contents = $$v
                      },
                      expression: "contents",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible8 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.refuseto()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.titles,
                visible: _vm.dialogFormVisible9,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible9 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("分类类型：")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "50%" },
                      attrs: { placeholder: "请选择分类类型" },
                      on: {
                        change: function ($event) {
                          return _vm.animeDatF()
                        },
                      },
                      model: {
                        value: _vm.parentIdnum,
                        callback: function ($$v) {
                          _vm.parentIdnum = $$v
                        },
                        expression: "parentIdnum",
                      },
                    },
                    _vm._l(_vm.classnum, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.parentIdnum == 2
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "200px",
                            display: "inline-block",
                            "text-align": "right",
                          },
                        },
                        [_vm._v("上级分类：")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "50%" },
                          model: {
                            value: _vm.parentId,
                            callback: function ($$v) {
                              _vm.parentId = $$v
                            },
                            expression: "parentId",
                          },
                        },
                        _vm._l(_vm.classDatas, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.gameName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("服务名称：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { type: "text", placeholder: "请输入服务名称" },
                    model: {
                      value: _vm.gameName,
                      callback: function ($$v) {
                        _vm.gameName = $$v
                      },
                      expression: "gameName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("排序：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      type: "number",
                      min: "0",
                      placeholder: "请输入排序",
                    },
                    model: {
                      value: _vm.sort,
                      callback: function ($$v) {
                        _vm.sort = $$v
                      },
                      expression: "sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px", display: "flex" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("服务图片：")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "148px",
                        height: "148px",
                        border: "1px dashed #c0ccda",
                        "border-radius": "6px",
                        "text-align": "center",
                        "line-height": "148px",
                      },
                    },
                    [
                      _c(
                        "custom-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.$http.adornUrl("oss/upload"),
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess1,
                            "on-progress": _vm.onprogress2,
                          },
                          model: {
                            value: _vm.gameImg,
                            callback: function ($$v) {
                              _vm.gameImg = $$v
                            },
                            expression: "gameImg",
                          },
                        },
                        [
                          _vm.gameImg != "" && _vm.percentage2 == 100
                            ? _c("img", {
                                staticClass: "avatar",
                                staticStyle: {
                                  "border-radius": "6px",
                                  width: "148px",
                                  height: "148px",
                                },
                                attrs: { src: _vm.gameImg },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon iconss",
                              }),
                          _vm.percentage2 > 0 && _vm.percentage2 < 100
                            ? _c("el-progress", {
                                attrs: {
                                  type: "circle",
                                  percentage: _vm.percentage2,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible9 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.refuseto1()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "评论列表",
                visible: _vm.dialogFormVisible10,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible10 = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoadingPl,
                      expression: "tableDataLoadingPl",
                    },
                  ],
                  attrs: { data: _vm.pinglunData.records },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "evaluateId", label: "编号", width: "80" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "userName",
                      label: "评论用户",
                      width: "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", {
                              staticStyle: {
                                color: "#4f9dec",
                                cursor: "pointer",
                              },
                              domProps: {
                                textContent: _vm._s(scope.row.userName),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updatesvideo(scope.row)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "evaluateMessage", label: "评论内容" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "evaluateImg",
                      label: "评论图片",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.evaluateImg
                              ? _c(
                                  "div",
                                  _vm._l(
                                    scope.row.evaluateImg.split(","),
                                    function (item, index) {
                                      return _c(
                                        "span",
                                        { key: index },
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top-start",
                                                title: "",
                                                trigger: "hover",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "50px",
                                                  height: "50px",
                                                  margin: "3px",
                                                },
                                                attrs: {
                                                  slot: "reference",
                                                  src: item,
                                                  alt: "",
                                                },
                                                slot: "reference",
                                              }),
                                              _c("img", {
                                                staticStyle: {
                                                  width: "200px",
                                                  height: "200px",
                                                },
                                                attrs: { src: item, alt: "" },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "satisfactionFlag",
                      label: "评分",
                      width: "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.satisfactionFlag == 1
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      cursor: "pointer",
                                    },
                                  },
                                  [_vm._v("非常差")]
                                )
                              : _vm._e(),
                            scope.row.satisfactionFlag == 2
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "orange",
                                      cursor: "pointer",
                                    },
                                  },
                                  [_vm._v("差")]
                                )
                              : _vm._e(),
                            scope.row.satisfactionFlag == 3
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#999",
                                      cursor: "pointer",
                                    },
                                  },
                                  [_vm._v("一般")]
                                )
                              : _vm._e(),
                            scope.row.satisfactionFlag == 4
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                  },
                                  [_vm._v("满意")]
                                )
                              : _vm._e(),
                            scope.row.satisfactionFlag == 5
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4f9dec",
                                      cursor: "pointer",
                                    },
                                  },
                                  [_vm._v("非常满意")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "评论时间",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      prop: "id",
                      width: "100",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  disabled: !_vm.isAuth("locality:delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.pinglundelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 40],
                      "page-size": _vm.size2,
                      "current-page": _vm.page2,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.pinglunData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange3,
                      "current-change": _vm.handleCurrentChange3,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "服务详情",
                visible: _vm.dialogFormVisibleDe,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisibleDe = $event
                },
              },
            },
            [
              _c("div", {
                staticClass: "texts",
                domProps: { innerHTML: _vm._s(_vm.texts) },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "site-navbar",
      class: "site-navbar--" + _vm.navbarLayoutType,
    },
    [
      _c("div", { staticClass: "site-navbar__header" }, [
        _c("h1", { staticClass: "site-navbar__brand" }, [
          _c(
            "a",
            {
              staticClass: "site-navbar__brand-lg",
              attrs: { href: "javascript:;" },
            },
            [_vm._v(_vm._s(_vm.entryName))]
          ),
          _vm._m(0),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "site-navbar__body clearfix" },
        [
          _c(
            "el-menu",
            { staticClass: "site-navbar__menu", attrs: { mode: "horizontal" } },
            [
              _c(
                "el-menu-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { index: "1" },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("audio", {
                      ref: "audio",
                      attrs: {
                        id: "audio",
                        autoplay: "autoplay",
                        src: "https://pw.xianmxkj.com/file/uploadPath/2022/01/19/0753211f78d718d44ee6372e33eae9ee.mp3",
                        controls: "",
                        hidden: "",
                      },
                    }),
                  ]),
                ],
                2
              ),
              _c(
                "el-menu-item",
                {
                  staticClass: "site-navbar__switch",
                  attrs: { index: "0" },
                  on: {
                    click: function ($event) {
                      _vm.sidebarFold = !_vm.sidebarFold
                    },
                  },
                },
                [_c("icon-svg", { attrs: { name: "zhedie" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "el-menu",
            {
              staticClass: "site-navbar__menu site-navbar__menu--right",
              attrs: { mode: "horizontal" },
            },
            [
              _c(
                "el-menu-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: { index: "1" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "vueMchat" })
                    },
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _vm.chatNum
                        ? _c("span", { staticClass: "left-number" }, [
                            _vm._v(_vm._s(_vm.chatNum)),
                          ])
                        : _vm._e(),
                      _c("icon-svg", {
                        staticClass: "el-icon-setting",
                        staticStyle: { "font-size": "28px" },
                        attrs: { name: "tixing" },
                      }),
                      _c("audio", {
                        ref: "audio",
                        attrs: {
                          id: "audio",
                          autoplay: "autoplay",
                          src: "https://pw.xianmxkj.com/file/uploadPath/2022/01/19/0753211f78d718d44ee6372e33eae9ee.mp3",
                          controls: "",
                          hidden: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-menu-item",
                { staticClass: "site-navbar__avatar", attrs: { index: "3" } },
                [
                  _c(
                    "el-dropdown",
                    { attrs: { "show-timeout": 0, placement: "bottom" } },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _vm._v(
                              _vm._s(_vm.username ? _vm.username : "") + " "
                            ),
                            _vm.username
                              ? _c("span", [_vm._v(",欢迎您登录")])
                              : _vm._e(),
                          ]
                        ),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/avatar.png"),
                            alt: _vm.userName,
                          },
                        }),
                      ]),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            {
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.logoutHandle()
                                },
                              },
                            },
                            [_vm._v("退出")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.updatePassowrdVisible
        ? _c("update-password", { ref: "updatePassowrd" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "site-navbar__brand-mini",
        attrs: { href: "javascript:;" },
      },
      [
        _c("img", {
          staticStyle: {
            width: "30px",
            height: "30px",
            "border-radius": "50%",
          },
          attrs: { src: require("@/assets/img/avatar.png") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
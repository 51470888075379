import { render, staticRenderFns } from "./autonymPeople.vue?vue&type=template&id=ad2ceac8"
import script from "./autonymPeople.vue?vue&type=script&lang=js"
export * from "./autonymPeople.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/prodctyun-yugao-maintenance-manage-consumer-web/code_dir-a/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ad2ceac8')) {
      api.createRecord('ad2ceac8', component.options)
    } else {
      api.reload('ad2ceac8', component.options)
    }
    module.hot.accept("./autonymPeople.vue?vue&type=template&id=ad2ceac8", function () {
      api.rerender('ad2ceac8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/autonym/autonymPeople.vue"
export default component.exports
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "用户反馈", name: "first" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.tableData.list },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "联系方式" },
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "内容" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createAt", label: "创建时间", width: "160" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.tableData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "投诉消息", name: "second" } },
        [
          _c("span", [_vm._v("投诉类型：")]),
          _c(
            "el-select",
            {
              staticStyle: { width: "150px", "margin-left": "10px" },
              on: {
                change: function ($event) {
                  return _vm.animeDat4(_vm.platform1)
                },
              },
              model: {
                value: _vm.platform1,
                callback: function ($$v) {
                  _vm.platform1 = $$v
                },
                expression: "platform1",
              },
            },
            _vm._l(_vm.platformList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _vm._v("    "),
          _c("span", [_vm._v("状态：")]),
          _c(
            "el-select",
            {
              staticStyle: { width: "150px", "margin-left": "10px" },
              on: {
                change: function ($event) {
                  return _vm.animeDat4(_vm.status)
                },
              },
              model: {
                value: _vm.statusT,
                callback: function ($$v) {
                  _vm.statusT = $$v
                },
                expression: "statusT",
              },
            },
            _vm._l(_vm.statusList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _vm._v("    "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.tableDataTs.list },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "投诉用户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#0066CC",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updataDetails(scope.row.userId)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.userName) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "被投诉用户" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#0066CC",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updataDetails(scope.row.byUserId)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.byUserName) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "platform", label: "投诉类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.platform == 1
                          ? _c("div", [_vm._v("聊天投诉")])
                          : _vm._e(),
                        scope.row.platform == 2
                          ? _c("div", [_vm._v("用户投诉")])
                          : _vm._e(),
                        scope.row.platform == 3
                          ? _c("div", [_vm._v("任务投诉")])
                          : _vm._e(),
                        scope.row.platform == 4
                          ? _c("div", [_vm._v("服务投诉")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "投诉内容", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { prop: "image", label: "图片", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.image, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: {
                              display: "inline-block",
                              margin: "3px",
                            },
                          },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  title: "",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    src: item,
                                    alt: "",
                                  },
                                  slot: "reference",
                                }),
                                _c("img", {
                                  staticStyle: {
                                    width: "300px",
                                    height: "auto",
                                  },
                                  attrs: { src: item, alt: "" },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c("div", [_vm._v("待审核")])
                          : scope.row.status == 1
                          ? _c("div", [_vm._v("已驳回")])
                          : _c("div", [_vm._v("已审核")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "处理结果" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c("div", [_vm._v("待处理")])
                          : _vm._e(),
                        scope.row.status == 1
                          ? _c("div", [_vm._v("已驳回")])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("div", [_vm._v("已封号")])
                          : _vm._e(),
                        scope.row.status == 3
                          ? _c("div", [_vm._v("已删除")])
                          : _vm._e(),
                        scope.row.status == 4
                          ? _c("div", [_vm._v("不处理")])
                          : _vm._e(),
                        scope.row.status == 5
                          ? _c("div", [_vm._v("已处理")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "auditContent", label: "审核/处理内容" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createAt", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.refuseClick(scope.row)
                                  },
                                },
                              },
                              [_vm._v("处理 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 50, 100],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.tableDataTs.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange1,
                  "current-change": _vm.handleCurrentChange1,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "消息公告", name: "four" } },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "right", margin: "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled: !_vm.isAuth("message:add"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addClick()
                    },
                  },
                },
                [_vm._v("添加 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.tableDataG.list },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "公告内容" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createAt", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("message:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("message:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.tableDataG.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "投诉处理",
            visible: _vm.dialogFormVisible,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("审核结果：")]
              ),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("通过")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("驳回")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.radio === 1
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                        position: "relative",
                        top: "-70px",
                      },
                    },
                    [_vm._v("驳回理由：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入驳回理由",
                    },
                    model: {
                      value: _vm.content,
                      callback: function ($$v) {
                        _vm.content = $$v
                      },
                      expression: "content",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.radio === 0
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("处理结果：")]
                  ),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.clStatus,
                        callback: function ($$v) {
                          _vm.clStatus = $$v
                        },
                        expression: "clStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("封号")]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("处理")]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("不处理"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.radio === 0
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                        position: "relative",
                        top: "-70px",
                      },
                    },
                    [_vm._v("处理说明：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "请输入处理说明",
                    },
                    model: {
                      value: _vm.content,
                      callback: function ($$v) {
                        _vm.content = $$v
                      },
                      expression: "content",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.refuseto()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titles,
            visible: _vm.dialogFormVisibleG,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleG = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                    position: "relative",
                    top: "-70px",
                  },
                },
                [_vm._v("公告内容：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "请输入公告内容",
                },
                model: {
                  value: _vm.title,
                  callback: function ($$v) {
                    _vm.title = $$v
                  },
                  expression: "title",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisibleG = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.refusetoBtn()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <el-upload v-bind="$attrs" v-on="$listeners" :headers="getHeaders">
        <slot></slot>
    </el-upload>
</template>
<script>
export default {
    props: {
        headers: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    computed: {
        getHeaders() {
            if(this.headers) {

                return {
                    ...this.headers,
                    Token: this.$cookie.get('token')
                }
            }
            return {
                Token: this.$cookie.get('token')
            }
        }
    }
}
</script>
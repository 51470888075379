var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { float: "right", "margin-right": "2%" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { margin: "10px 0" },
              attrs: {
                disabled: !_vm.isAuth("materialLink:add"),
                size: "mini",
                type: "primary",
                icon: "document",
              },
              on: { click: _vm.advertNotice },
            },
            [_vm._v("添加素材")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableDataLoading,
              expression: "tableDataLoading",
            },
          ],
          attrs: { data: _vm.guangaoData },
        },
        [
          _c("el-table-column", {
            attrs: { fixed: "", prop: "id", label: "编号", width: "80" },
          }),
          _c("el-table-column", {
            attrs: { prop: "imgUrl", label: "素材图片", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      attrs: {
                        src: scope.row.image_url,
                        alt: "",
                        width: "170px",
                        height: "60",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { prop: "url", label: "素材链接" } }),
          _c("el-table-column", {
            attrs: { prop: "createAt", label: "创建时间", width: "180" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          disabled: !_vm.isAuth("materialLink:update"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.advertUpdates(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "danger",
                          disabled: !_vm.isAuth("materialLink:delete"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.advertdeletes(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加素材",
            visible: _vm.dialogFormVisible,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px", display: "flex" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                  },
                },
                [_vm._v("素材图片：")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "148px",
                    height: "148px",
                    border: "1px dashed #c0ccda",
                    "border-radius": "6px",
                    "text-align": "center",
                    "line-height": "148px",
                  },
                },
                [
                  _c(
                    "custom-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.$http.adornUrl("oss/upload"),
                        "show-file-list": false,
                        "on-success": _vm.handleAvatarSuccess,
                      },
                      model: {
                        value: _vm.imgUrl,
                        callback: function ($$v) {
                          _vm.imgUrl = $$v
                        },
                        expression: "imgUrl",
                      },
                    },
                    [
                      _vm.imgUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            staticStyle: {
                              "border-radius": "6px",
                              width: "148px",
                              height: "148px",
                            },
                            attrs: { src: _vm.imgUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    width: "200px",
                    display: "inline-block",
                    "text-align": "right",
                    position: "relative",
                    top: "-68px",
                  },
                },
                [_vm._v("素材地址：")]
              ),
              _c("el-input", {
                staticStyle: { width: "50%" },
                attrs: {
                  placeholder: "请输入素材地址",
                  type: "textarea",
                  rows: 4,
                },
                model: {
                  value: _vm.linkUrl,
                  callback: function ($$v) {
                    _vm.linkUrl = $$v
                  },
                  expression: "linkUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.advertNoticeTo()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改素材",
            visible: _vm.dialogFormVisible1,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible1 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "素材图片：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "148px",
                        height: "148px",
                        border: "1px dashed #c0ccda",
                        "border-radius": "6px",
                        "text-align": "center",
                        "line-height": "148px",
                      },
                    },
                    [
                      _c(
                        "custom-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.$http.adornUrl("oss/upload"),
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess1,
                          },
                          model: {
                            value: _vm.form.imgUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "imgUrl", $$v)
                            },
                            expression: "form.imgUrl",
                          },
                        },
                        [
                          _vm.form.imgUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                staticStyle: {
                                  "border-radius": "6px",
                                  width: "148px",
                                  height: "148px",
                                },
                                attrs: { src: _vm.form.imgUrl },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "素材链接：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "65%" },
                    attrs: { type: "textarea", rows: 4 },
                    model: {
                      value: _vm.form.linkUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "linkUrl", $$v)
                      },
                      expression: "form.linkUrl",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible1 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.amendadvertTo()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "components-container main-cont" }, [
    _c(
      "div",
      { staticStyle: { position: "relative" } },
      [
        _c("span", [_vm._v("服务标题：")]),
        _c("el-input", {
          staticStyle: { width: "200px" },
          attrs: { autosize: "" },
          model: {
            value: _vm.title,
            callback: function ($$v) {
              _vm.title = $$v
            },
            expression: "title",
          },
        }),
        _vm._v("     "),
        _c("span", [_vm._v("服务分类：")]),
        _c(
          "el-select",
          {
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请选择服务分类" },
            model: {
              value: _vm.classifyId,
              callback: function ($$v) {
                _vm.classifyId = $$v
              },
              expression: "classifyId",
            },
          },
          _vm._l(_vm.artiCletypes, function (item, index) {
            return _c("el-option", {
              key: item.index,
              attrs: { label: item.classifyName, value: item.id },
            })
          }),
          1
        ),
        _vm._v("     "),
        _c("span", [_vm._v("幸运值：")]),
        _c("el-input", {
          staticStyle: { width: "200px" },
          attrs: { type: "number", autosize: "" },
          model: {
            value: _vm.luckyValue,
            callback: function ($$v) {
              _vm.luckyValue = $$v
            },
            expression: "luckyValue",
          },
        }),
        _vm._v("   "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              margin: "2% 0",
            },
          },
          [
            _c("span", { staticStyle: { "margin-right": "5px" } }, [
              _vm._v("标题图片："),
            ]),
            _c(
              "custom-upload",
              {
                class: { hide: _vm.hideUpload },
                attrs: {
                  action: _vm.$http.adornUrl("oss/upload"),
                  limit: 1,
                  "on-success": _vm.handleUploadSuccess,
                  "list-type": "picture-card",
                  "on-remove": _vm.handleRemove,
                  "on-change": _vm.handleChange,
                },
                model: {
                  value: _vm.titlePicture,
                  callback: function ($$v) {
                    _vm.titlePicture = $$v
                  },
                  expression: "titlePicture",
                },
              },
              [
                _vm.titlePicture
                  ? _c("img", {
                      staticClass: "avatar",
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        "border-radius": "4px",
                      },
                      attrs: { src: _vm.titlePicture },
                    })
                  : _vm._e(),
                _c("i", { staticClass: "el-icon-plus" }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { position: "absolute", right: "0", top: "5px" } },
          [
            _c(
              "el-button",
              {
                staticStyle: { margin: "0 0 20px 20px" },
                attrs: { size: "mini", type: "primary", icon: "document" },
                on: { click: _vm.artiReleass },
              },
              [_vm._v("修改服务 ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "tinymce-content" }, [
      _c("textarea", {
        staticClass: "tinymce-textarea",
        attrs: { id: "tinymceId" },
        domProps: { value: _vm.content },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { marginTop: _vm.marginTop + "px" } },
    [
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.previewImg,
                expression: "previewImg",
              },
            ],
            staticClass: "preview-img",
            style: {
              width: _vm.uploadWidth + "px",
              height: _vm.uploadHeight + "px",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "preview-img-box",
                style: { lineHeight: _vm.uploadHeight + "px" },
              },
              [
                _c("i", {
                  staticClass: "el-icon-delete",
                  on: { click: _vm.deleteFn },
                }),
                _c("i", {
                  staticClass: "el-icon-view",
                  on: { click: _vm.viewFn },
                }),
                _c("i", {
                  staticClass: "el-icon-refresh",
                  on: { click: _vm.refreshFn },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "preview-img-number" },
              [_vm._t("preview-img-number")],
              2
            ),
            _c("img", {
              style: {
                width: "100%",
                height: "100%",
                objectFit: _vm.objectFit,
              },
              attrs: { src: _vm.previewImg },
            }),
          ]
        ),
        _c(
          "el-upload",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.previewImg,
                expression: "!previewImg",
              },
            ],
            ref: "upload",
            staticClass: "upload-demo",
            style: {
              width: _vm.uploadWidth + "px",
              minHeight: _vm.uploadHeight + "px",
            },
            attrs: {
              action: _vm.actionUrl,
              "on-change": _vm.handleChangeUpload,
              "auto-upload": false,
              "show-file-list": false,
              disabled: _vm.disabled,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "upload-demo-icon",
                style: {
                  width: _vm.uploadWidth + "px",
                  height: _vm.uploadHeight + "px",
                  lineHeight: _vm.uploadHeight + "px",
                },
              },
              [_vm._v(" + ")]
            ),
          ]
        ),
      ],
      _c(
        "el-dialog",
        {
          staticClass: "crop-dialog",
          attrs: {
            title: "图片剪裁",
            visible: _vm.dialogVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { padding: "0 20px" } }, [
            _c(
              "div",
              {
                style: {
                  textAlign: "center",
                  width:
                    _vm.cropperWidth != 0 ? _vm.cropperWidth + "px" : "auto",
                  height: _vm.cropperHeight + "px",
                },
              },
              [
                _c("VueCropper", {
                  ref: "cropper",
                  attrs: {
                    img: _vm.cropperImg,
                    "output-size": _vm.outputSize,
                    "output-type": _vm.outputType,
                    info: _vm.info,
                    full: _vm.full,
                    "can-move": _vm.canMove,
                    "can-move-box": _vm.canMoveBox,
                    original: _vm.original,
                    "auto-crop": _vm.autoCrop,
                    "can-scale": _vm.canScale,
                    fixed: _vm.fixed,
                    "fixed-number": _vm.fixedNumber,
                    "fixed-box": _vm.fixedBox,
                    "center-box": _vm.centerBox,
                    "info-true": _vm.infoTrue,
                    "auto-crop-width": _vm.autoCropWidth,
                    "auto-crop-height": _vm.autoCropHeight,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.actionButtonFlag
            ? _c(
                "div",
                { staticClass: "action-box" },
                [
                  _c(
                    "el-upload",
                    {
                      staticStyle: { "margin-right": "15px" },
                      attrs: {
                        action: "#",
                        "auto-upload": false,
                        "show-file-list": false,
                        "on-change": _vm.handleChangeUpload,
                      },
                    },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("更换图片"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.clearImgHandle },
                    },
                    [_vm._v(" 清除图片 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.rotateLeftHandle },
                    },
                    [_vm._v(" 左旋转 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.rotateRightHandle },
                    },
                    [_vm._v(" 右旋转 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.changeScaleHandle(1)
                        },
                      },
                    },
                    [_vm._v(" 放大 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.changeScaleHandle(-1)
                        },
                      },
                    },
                    [_vm._v(" 缩小 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.fixed = !_vm.fixed
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.fixed ? "固定比例" : "自由比例") + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadHandle("blob")
                        },
                      },
                    },
                    [_vm._v(" 下载 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.finish },
                },
                [_vm._v(" 确认 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片预览",
            visible: _vm.previewDialogVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewDialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.previewImg },
          }),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.previewDialogVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
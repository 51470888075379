var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "全部用户", name: "first" } },
            [
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("昵称:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入昵称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.campus,
                      callback: function ($$v) {
                        _vm.campus = $$v
                      },
                      expression: "campus",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("手机号:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入手机号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.phone,
                      callback: function ($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.invitationCode,
                      callback: function ($$v) {
                        _vm.invitationCode = $$v
                      },
                      expression: "invitationCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请人邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请人邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.inviterCode,
                      callback: function ($$v) {
                        _vm.inviterCode = $$v
                      },
                      expression: "inviterCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.select },
                    },
                    [_vm._v("查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.cleans },
                    },
                    [_vm._v("重置 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.tableData.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "userId",
                      label: "编号",
                      width: "80",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "userName",
                      label: "昵称",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "isAuthentication", label: "性别" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.sex == 1
                              ? _c("span", [_vm._v("男")])
                              : scope.row.sex == 2
                              ? _c("span", [_vm._v("女")])
                              : _c("span", [_vm._v("未设置")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "图像" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.avatar == null
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/avatar.png"),
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: scope.row.avatar,
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "手机号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.phone
                                        ? scope.row.phone
                                        : "未绑定"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "用户类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("个人认证")])
                              : scope.row.isAuthentication == 2
                              ? _c("span", [_vm._v("企业认证")])
                              : scope.row.isAuthentication == 3
                              ? _c("span", [_vm._v("个人企业认证")])
                              : _c("span", [_vm._v("普通用户")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "invitationCode", label: "邀请码" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "邀请人邀请码" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates2(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.inviterCode
                                      ? scope.row.inviterCode
                                      : "未绑定"
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "是否实名" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication
                              ? _c("span", [_vm._v("是")])
                              : _c("span", [_vm._v("否")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "wxImg", label: "收款二维码", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.wxImg == null || scope.row.wxImg == ""
                              ? _c("div", [_vm._v(" 暂无收款二维码 ")])
                              : _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-wrap": "wrap",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { margin: "2px" } },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top-start",
                                              title: "",
                                              trigger: "hover",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "50px",
                                                height: "50px",
                                              },
                                              attrs: {
                                                slot: "reference",
                                                src: scope.row.wxImg,
                                                alt: "",
                                              },
                                              slot: "reference",
                                            }),
                                            _c("img", {
                                              staticStyle: {
                                                width: "300px",
                                                height: "300px",
                                              },
                                              attrs: {
                                                src: scope.row.wxImg,
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "支付宝名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBaoName
                                    ? scope.row.zhiFuBaoName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBao", label: "支付宝账号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBao
                                    ? scope.row.zhiFuBao
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "rate",
                      label: "商家佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.rate ? scope.row.rate : "0")
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "rate")
                                  },
                                },
                              },
                              [_vm._v(" 修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "zhiRate",
                      label: "一级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiRate ? scope.row.zhiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "zhiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "feiRate",
                      label: "二级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.feiRate ? scope.row.feiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "feiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vipEndTime",
                      label: "会员到期时间",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "state ", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-value": _vm.openValue,
                                disabled: !_vm.isAuth("userList:delete"),
                                "inactive-value": _vm.closeValue,
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.change(
                                    scope.row.status,
                                    scope.row.userId
                                  )
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [_vm._v("用户详情")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "warning",
                                  disabled: !_vm.isAuth("userList:updateVip"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.zengsongVip(scope.row)
                                  },
                                },
                              },
                              [_vm._v("修改会员")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  disabled: !_vm.isAuth("userList:delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteuser(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除用户")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 40, 50],
                      "page-size": _vm.limit,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.tableData.totalCount,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "会员用户", name: "isvips" } },
            [
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("手机号:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入手机号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.phone,
                      callback: function ($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("昵称:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入昵称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.campus,
                      callback: function ($$v) {
                        _vm.campus = $$v
                      },
                      expression: "campus",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.invitationCode,
                      callback: function ($$v) {
                        _vm.invitationCode = $$v
                      },
                      expression: "invitationCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请人邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请人邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.inviterCode,
                      callback: function ($$v) {
                        _vm.inviterCode = $$v
                      },
                      expression: "inviterCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.select },
                    },
                    [_vm._v("查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.cleans },
                    },
                    [_vm._v("重置 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.tableData.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "userId",
                      label: "编号",
                      width: "80",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "userName",
                      label: "昵称",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "isAuthentication", label: "性别" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.sex == 1
                              ? _c("span", [_vm._v("男")])
                              : scope.row.sex == 2
                              ? _c("span", [_vm._v("女")])
                              : _c("span", [_vm._v("未设置")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "图像" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.avatar == null
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/avatar.png"),
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: scope.row.avatar,
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "手机号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.phone
                                        ? scope.row.phone
                                        : "未绑定"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "用户类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("个人认证")])
                              : scope.row.isAuthentication == 2
                              ? _c("span", [_vm._v("企业认证")])
                              : scope.row.isAuthentication == 3
                              ? _c("span", [_vm._v("个人企业认证")])
                              : _c("span", [_vm._v("普通用户")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "invitationCode", label: "邀请码" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "邀请人邀请码" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates2(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.inviterCode
                                      ? scope.row.inviterCode
                                      : "未绑定"
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "是否实名" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("是")])
                              : _c("span", [_vm._v("否")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "支付宝名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBaoName
                                    ? scope.row.zhiFuBaoName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBao", label: "支付宝账号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBao
                                    ? scope.row.zhiFuBao
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "rate",
                      label: "商家佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.rate ? scope.row.rate : "0")
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "rate")
                                  },
                                },
                              },
                              [_vm._v(" 修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "zhiRate",
                      label: "一级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiRate ? scope.row.zhiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "zhiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "feiRate",
                      label: "二级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.feiRate ? scope.row.feiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "feiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "state ", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-value": _vm.openValue,
                                disabled: !_vm.isAuth("userList:delete"),
                                "inactive-value": _vm.closeValue,
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.change(
                                    scope.row.status,
                                    scope.row.userId
                                  )
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [_vm._v("用户详情")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  disabled: !_vm.isAuth("userList:delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteuser(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除用户")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 40, 50],
                      "page-size": _vm.limit,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.tableData.totalCount,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "普通用户", name: "second" } },
            [
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("手机号:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入手机号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.phone,
                      callback: function ($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("昵称:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入昵称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.campus,
                      callback: function ($$v) {
                        _vm.campus = $$v
                      },
                      expression: "campus",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.invitationCode,
                      callback: function ($$v) {
                        _vm.invitationCode = $$v
                      },
                      expression: "invitationCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请人邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请人邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.inviterCode,
                      callback: function ($$v) {
                        _vm.inviterCode = $$v
                      },
                      expression: "inviterCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.select },
                    },
                    [_vm._v("查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.cleans },
                    },
                    [_vm._v("重置 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.tableData.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "userId",
                      label: "编号",
                      width: "80",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "userName",
                      label: "昵称",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "isAuthentication", label: "性别" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.sex == 1
                              ? _c("span", [_vm._v("男")])
                              : scope.row.sex == 2
                              ? _c("span", [_vm._v("女")])
                              : _c("span", [_vm._v("未设置")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "图像" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.avatar == null
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/avatar.png"),
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: scope.row.avatar,
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "手机号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.phone
                                        ? scope.row.phone
                                        : "未绑定"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "用户类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("个人认证")])
                              : scope.row.isAuthentication == 2
                              ? _c("span", [_vm._v("企业认证")])
                              : scope.row.isAuthentication == 3
                              ? _c("span", [_vm._v("个人企业认证")])
                              : _c("span", [_vm._v("普通用户")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "invitationCode", label: "邀请码" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "邀请人邀请码" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates2(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.inviterCode
                                      ? scope.row.inviterCode
                                      : "未绑定"
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "是否实名" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("是")])
                              : _c("span", [_vm._v("否")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "支付宝名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBaoName
                                    ? scope.row.zhiFuBaoName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBao", label: "支付宝账号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBao
                                    ? scope.row.zhiFuBao
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "rate",
                      label: "商家佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.rate ? scope.row.rate : "0")
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "rate")
                                  },
                                },
                              },
                              [_vm._v(" 修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "zhiRate",
                      label: "一级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiRate ? scope.row.zhiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "zhiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "feiRate",
                      label: "二级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.feiRate ? scope.row.feiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "feiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "state ", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-value": _vm.openValue,
                                disabled: !_vm.isAuth("userList:delete"),
                                "inactive-value": _vm.closeValue,
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.change(
                                    scope.row.status,
                                    scope.row.userId
                                  )
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [_vm._v("用户详情")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  disabled: !_vm.isAuth("userList:delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteuser(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除用户")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 40, 50],
                      "page-size": _vm.limit,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.tableData.totalCount,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "商家用户", name: "third" } },
            [
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("手机号:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入手机号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.phone,
                      callback: function ($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("昵称:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入昵称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.campus,
                      callback: function ($$v) {
                        _vm.campus = $$v
                      },
                      expression: "campus",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.invitationCode,
                      callback: function ($$v) {
                        _vm.invitationCode = $$v
                      },
                      expression: "invitationCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请人邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请人邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.inviterCode,
                      callback: function ($$v) {
                        _vm.inviterCode = $$v
                      },
                      expression: "inviterCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.select },
                    },
                    [_vm._v("查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.cleans },
                    },
                    [_vm._v("重置 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.tableData.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "userId",
                      label: "编号",
                      width: "80",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "userName",
                      label: "昵称",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "isAuthentication", label: "性别" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.sex == 1
                              ? _c("span", [_vm._v("男")])
                              : scope.row.sex == 2
                              ? _c("span", [_vm._v("女")])
                              : _c("span", [_vm._v("未设置")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "图像" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.avatar == null
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/avatar.png"),
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: scope.row.avatar,
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "手机号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.phone
                                        ? scope.row.phone
                                        : "未绑定"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "用户类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("个人认证")])
                              : scope.row.isAuthentication == 2
                              ? _c("span", [_vm._v("企业认证")])
                              : scope.row.isAuthentication == 3
                              ? _c("span", [_vm._v("个人企业认证")])
                              : _c("span", [_vm._v("普通用户")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "invitationCode", label: "邀请码" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "邀请人邀请码" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates2(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.inviterCode
                                      ? scope.row.inviterCode
                                      : "未绑定"
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "是否实名" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("是")])
                              : _c("span", [_vm._v("否")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "支付宝名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBaoName
                                    ? scope.row.zhiFuBaoName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBao", label: "支付宝账号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBao
                                    ? scope.row.zhiFuBao
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "rate",
                      label: "商家佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.rate ? scope.row.rate : "0")
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "rate")
                                  },
                                },
                              },
                              [_vm._v(" 修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "zhiRate",
                      label: "一级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiRate ? scope.row.zhiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "zhiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "feiRate",
                      label: "二级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.feiRate ? scope.row.feiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "feiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "state ", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-value": _vm.openValue,
                                disabled: !_vm.isAuth("userList:delete"),
                                "inactive-value": _vm.closeValue,
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.change(
                                    scope.row.status,
                                    scope.row.userId
                                  )
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [_vm._v("用户详情")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  disabled: !_vm.isAuth("userList:delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteuser(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除用户")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 40, 50],
                      "page-size": _vm.limit,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.tableData.totalCount,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "师傅用户", name: "five" } },
            [
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("手机号:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入手机号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.phone,
                      callback: function ($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("昵称:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入昵称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.campus,
                      callback: function ($$v) {
                        _vm.campus = $$v
                      },
                      expression: "campus",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.invitationCode,
                      callback: function ($$v) {
                        _vm.invitationCode = $$v
                      },
                      expression: "invitationCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请人邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请人邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.inviterCode,
                      callback: function ($$v) {
                        _vm.inviterCode = $$v
                      },
                      expression: "inviterCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.select },
                    },
                    [_vm._v("查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.cleans },
                    },
                    [_vm._v("重置 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.tableData.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "userId",
                      label: "编号",
                      width: "80",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "userName",
                      label: "昵称",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "isAuthentication", label: "性别" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.sex == 1
                              ? _c("span", [_vm._v("男")])
                              : scope.row.sex == 2
                              ? _c("span", [_vm._v("女")])
                              : _c("span", [_vm._v("未设置")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "图像" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.avatar == null
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/avatar.png"),
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: scope.row.avatar,
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "手机号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.phone
                                        ? scope.row.phone
                                        : "未绑定"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "用户类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("个人认证")])
                              : scope.row.isAuthentication == 2
                              ? _c("span", [_vm._v("企业认证")])
                              : scope.row.isAuthentication == 3
                              ? _c("span", [_vm._v("个人企业认证")])
                              : _c("span", [_vm._v("普通用户")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "invitationCode", label: "邀请码" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "邀请人邀请码" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates2(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.inviterCode
                                      ? scope.row.inviterCode
                                      : "未绑定"
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "是否实名" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("是")])
                              : _c("span", [_vm._v("否")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "支付宝名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBaoName
                                    ? scope.row.zhiFuBaoName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBao", label: "支付宝账号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBao
                                    ? scope.row.zhiFuBao
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "rate",
                      label: "商家佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.rate ? scope.row.rate : "0")
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "rate")
                                  },
                                },
                              },
                              [_vm._v(" 修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "zhiRate",
                      label: "一级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiRate ? scope.row.zhiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "zhiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "feiRate",
                      label: "二级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.feiRate ? scope.row.feiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "feiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "state ", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-value": _vm.openValue,
                                disabled: !_vm.isAuth("userList:delete"),
                                "inactive-value": _vm.closeValue,
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.change(
                                    scope.row.status,
                                    scope.row.userId
                                  )
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [_vm._v("用户详情")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  disabled: !_vm.isAuth("userList:delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteuser(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除用户")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 40, 50],
                      "page-size": _vm.limit,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.tableData.totalCount,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "员工用户", name: "four" } },
            [
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("手机号:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入手机号" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.phone,
                      callback: function ($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("昵称:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入昵称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.campus,
                      callback: function ($$v) {
                        _vm.campus = $$v
                      },
                      expression: "campus",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("商家名称:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入商家名称" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.companyName,
                      callback: function ($$v) {
                        _vm.companyName = $$v
                      },
                      expression: "companyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.invitationCode,
                      callback: function ($$v) {
                        _vm.invitationCode = $$v
                      },
                      expression: "invitationCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { margin: "2% 0", display: "inline-block" } },
                [
                  _c("span", [_vm._v("邀请人邀请码:")]),
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { clearable: "", placeholder: "请输入邀请人邀请码" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.select.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.inviterCode,
                      callback: function ($$v) {
                        _vm.inviterCode = $$v
                      },
                      expression: "inviterCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v("   "),
              _c(
                "div",
                { staticStyle: { display: "inline-block" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.select },
                    },
                    [_vm._v("查询 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "document",
                      },
                      on: { click: _vm.cleans },
                    },
                    [_vm._v("重置 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { data: _vm.tableData.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "userId",
                      label: "编号",
                      width: "80",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "userName",
                      label: "昵称",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.userName
                                    ? scope.row.userName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "isAuthentication", label: "性别" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.sex == 1
                              ? _c("span", [_vm._v("男")])
                              : scope.row.sex == 2
                              ? _c("span", [_vm._v("女")])
                              : _c("span", [_vm._v("未设置")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "图像" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.avatar == null
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/avatar.png"),
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: scope.row.avatar,
                                    alt: "",
                                    width: "40",
                                    height: "40",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "手机号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.phone
                                        ? scope.row.phone
                                        : "未绑定"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone", label: "用户类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("个人认证")])
                              : scope.row.isAuthentication == 2
                              ? _c("span", [_vm._v("企业认证")])
                              : scope.row.isAuthentication == 3
                              ? _c("span", [_vm._v("个人企业认证")])
                              : _c("span", [_vm._v("普通用户")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "companyName", label: "商家名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "invitationCode", label: "邀请码" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "邀请人邀请码" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates2(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.inviterCode
                                      ? scope.row.inviterCode
                                      : "未绑定"
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "是否实名" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.isAuthentication == 1
                              ? _c("span", [_vm._v("是")])
                              : _c("span", [_vm._v("否")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBaoName", label: "支付宝名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBaoName
                                    ? scope.row.zhiFuBaoName
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zhiFuBao", label: "支付宝账号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiFuBao
                                    ? scope.row.zhiFuBao
                                    : "未绑定"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "rate",
                      label: "商家佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.rate ? scope.row.rate : "0")
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "rate")
                                  },
                                },
                              },
                              [_vm._v(" 修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "zhiRate",
                      label: "一级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.zhiRate ? scope.row.zhiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "zhiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "feiRate",
                      label: "二级佣金比例",
                      width: "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.feiRate ? scope.row.feiRate : "0"
                                )
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  background: "#fff",
                                  border: "none",
                                },
                                attrs: {
                                  size: "mini",
                                  disabled: !_vm.isAuth("userList:updatebl"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.xiugai(scope.row, "feiRate")
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "state ", label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-value": _vm.openValue,
                                disabled: !_vm.isAuth("userList:delete"),
                                "inactive-value": _vm.closeValue,
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.change(
                                    scope.row.status,
                                    scope.row.userId
                                  )
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updates(scope.row)
                                  },
                                },
                              },
                              [_vm._v("用户详情")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "danger",
                                  disabled: !_vm.isAuth("userList:delete"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteuser(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除用户")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "10px" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [10, 20, 30, 40, 50],
                      "page-size": _vm.limit,
                      "current-page": _vm.page,
                      layout: "total,sizes, prev, pager, next,jumper",
                      total: _vm.tableData.totalCount,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改佣金比例",
                visible: _vm.dialogFormVisible2,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible2 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("佣金比例：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      type: "number",
                      min: 0,
                      controls: false,
                      placeholder: "请输入佣金比例",
                    },
                    model: {
                      value: _vm.proportion,
                      callback: function ($$v) {
                        _vm.proportion = $$v
                      },
                      expression: "proportion",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible2 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.StairNoticeTo2()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改会员",
                visible: _vm.dialogFormVisibleZs,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisibleZs = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("类型：")]
                  ),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.type,
                        callback: function ($$v) {
                          _vm.type = $$v
                        },
                        expression: "type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("添加")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("减少")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("修改天数：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      type: "number",
                      min: 0,
                      controls: false,
                      placeholder: "请输入修改天数",
                    },
                    model: {
                      value: _vm.proportion,
                      callback: function ($$v) {
                        _vm.proportion = $$v
                      },
                      expression: "proportion",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisibleZs = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.updateVip()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
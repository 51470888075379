import { Config } from "./config";
import http from "@/utils/httpRequest";
import { Message } from 'element-ui'
// 第三方token获取token
export const thirdSsolog = async (data) => {
    return http({
      url: http.adornUrl(Config.thirdSsolog),
      method: 'post',
      data
    }).then(({
      data
    }) => {
      if (data.code == 0) {
        return data;
      } else {
        Message.error(data.msg)
      }
      return null;
    })
    .catch(err => {
      return null;
    });
    
};

// 获取商家服务分类接口
export const getMerchantCategory = async () => {
  return http({
    url: http.adornUrl('/admin/fixedClassify/getTreeClassifyList'),
    method: 'get',
  }).then(({
    data
  }) => {
    console.log(data);
    if (data.code == 0) {
      return parseTreeData(data.data);
    }
    return [];
  })
  .catch(err =>{
    return [];
  })
}

// 解析树形数据
export const parseTreeData = (data) => {
  if(data.length) {
    return data.map(el => {
      return {
        id: el.classifyId,
        label: el.classifyName,
        children: parseTreeData(el.children)
      };
    });
  }
  return [];
};


// 上传图片
export const uploadImg = async (param) => {
  return http({
    url: http.adornUrl('/oss/upload'),
    method: 'post',
    data:param
  }).then(({
    data
  }) => {
    console.log(data);
    if (data.code == 0) {
      return data;
    }
    return [];
  })
  .catch(err =>{
    return [];
  })
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": _vm.handleClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "首页公告", name: "first" } },
        [
          _c(
            "div",
            { staticStyle: { float: "right", "margin-right": "2%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: {
                    disabled: !_vm.isAuth("fitmentList:add"),
                    size: "mini",
                    type: "primary",
                    icon: "document",
                  },
                  on: { click: _vm.addNotice },
                },
                [_vm._v("添加公告")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.tableData.list },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "标题" },
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type === "url"
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("链接")]
                            )
                          : _vm._e(),
                        scope.row.type === "word"
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4f9dec",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("文本")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", { attrs: { prop: "url", label: "链接" } }),
              _c("el-table-column", {
                attrs: { prop: "createAt", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("fitmentList:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updates(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("修改 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("fitmentList:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deletes(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.tableData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加公告",
                visible: _vm.dialogFormVisible,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("标题：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入公告标题" },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("链接：")]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入公告链接" },
                    model: {
                      value: _vm.url,
                      callback: function ($$v) {
                        _vm.url = $$v
                      },
                      expression: "url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("类型：")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "50%" },
                      attrs: { placeholder: "请选择公告类型" },
                      model: {
                        value: _vm.type,
                        callback: function ($$v) {
                          _vm.type = $$v
                        },
                        expression: "type",
                      },
                    },
                    _vm._l(_vm.types, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.releasgongaoTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改",
                visible: _vm.dialogFormVisible1,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible1 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.form } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "标题：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "链接：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.form.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "url", $$v)
                          },
                          expression: "form.url",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "类型：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "65%" },
                          attrs: { placeholder: "请选择类型" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.types, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible1 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.amendNoticeTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "首页导航", name: "second" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.homeData },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "名称" },
              }),
              _c("el-table-column", { attrs: { prop: "url", label: "路由" } }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: {
                            src: scope.row.imageUrl,
                            alt: "",
                            width: "40",
                            height: "40",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createAt", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("fitmentList:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.compile(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.homeData.length,
                },
                on: {
                  "size-change": _vm.handleSizeChange1,
                  "current-change": _vm.handleCurrentChange1,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "首页推广", name: "third" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.homeData },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "名称" },
              }),
              _c("el-table-column", { attrs: { prop: "url", label: "路由" } }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: {
                            src: scope.row.imageUrl,
                            alt: "",
                            width: "40",
                            height: "40",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createAt", label: "创建时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("fitmentList:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.compile(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.homeData.length,
                },
                on: {
                  "size-change": _vm.handleSizeChange1,
                  "current-change": _vm.handleCurrentChange1,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "升级配置", name: "fourth" } },
        [
          _c(
            "div",
            { staticStyle: { float: "right", "margin-right": "2%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: {
                    disabled: !_vm.isAuth("fitmentList:add"),
                    size: "mini",
                    type: "primary",
                    icon: "document",
                  },
                  on: { click: _vm.addUpgrade },
                },
                [_vm._v("添加升级")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.upgradeData },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "wgtUrl", label: "统一地址" },
              }),
              _c("el-table-column", {
                attrs: { prop: "androidWgtUrl", label: "安卓" },
              }),
              _c("el-table-column", {
                attrs: { prop: "iosWgtUrl", label: "苹果" },
              }),
              _c("el-table-column", {
                attrs: { prop: "version", label: "版本" },
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "升级方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.method == "true"
                                ? "强制升级"
                                : "普通升级"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createAt", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: !_vm.isAuth("fitmentList:update"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.upgradebj(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("fitmentList:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.upgradelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "10px" } },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.upgradeData.length,
                },
                on: {
                  "size-change": _vm.handleSizeChange2,
                  "current-change": _vm.handleCurrentChange2,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加升级",
                visible: _vm.dialogFormVisible3,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible3 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("统一地址："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入统一地址" },
                    model: {
                      value: _vm.wgtUrl,
                      callback: function ($$v) {
                        _vm.wgtUrl = $$v
                      },
                      expression: "wgtUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("安卓地址："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入安卓地址" },
                    model: {
                      value: _vm.androidWgtUrl,
                      callback: function ($$v) {
                        _vm.androidWgtUrl = $$v
                      },
                      expression: "androidWgtUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("苹果地址："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入苹果地址" },
                    model: {
                      value: _vm.iosWgtUrl,
                      callback: function ($$v) {
                        _vm.iosWgtUrl = $$v
                      },
                      expression: "iosWgtUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("版本号："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入版本号" },
                    model: {
                      value: _vm.version,
                      callback: function ($$v) {
                        _vm.version = $$v
                      },
                      expression: "version",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("升级方式："),
                    ]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "50%" },
                      attrs: { placeholder: "请选择升级方式" },
                      model: {
                        value: _vm.method,
                        callback: function ($$v) {
                          _vm.method = $$v
                        },
                        expression: "method",
                      },
                    },
                    _vm._l(_vm.methods, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        width: "200px",
                        display: "inline-block",
                        "text-align": "right",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                            "margin-right": "2px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("升级内容："),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "请输入升级内容" },
                    model: {
                      value: _vm.des,
                      callback: function ($$v) {
                        _vm.des = $$v
                      },
                      expression: "des",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible3 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.upgradaddTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改",
                visible: _vm.dialogFormVisible4,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible4 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.formupgrad } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "统一地址：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.wgtUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "wgtUrl", $$v)
                          },
                          expression: "formupgrad.wgtUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "安卓地址：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.androidWgtUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "androidWgtUrl", $$v)
                          },
                          expression: "formupgrad.androidWgtUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "苹果地址：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.iosWgtUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "iosWgtUrl", $$v)
                          },
                          expression: "formupgrad.iosWgtUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "版本号：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.version,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "version", $$v)
                          },
                          expression: "formupgrad.version",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "升级方式：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "65%" },
                          attrs: { placeholder: "请选择升级方式" },
                          model: {
                            value: _vm.formupgrad.method,
                            callback: function ($$v) {
                              _vm.$set(_vm.formupgrad, "method", $$v)
                            },
                            expression: "formupgrad.method",
                          },
                        },
                        _vm._l(_vm.methods, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "升级内容：",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        model: {
                          value: _vm.formupgrad.des,
                          callback: function ($$v) {
                            _vm.$set(_vm.formupgrad, "des", $$v)
                          },
                          expression: "formupgrad.des",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible4 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.upgradbjTo()
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "精选商品", name: "fifth" } },
        [
          _c(
            "div",
            { staticStyle: { float: "right", "margin-right": "2%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: {
                    disabled: !_vm.isAuth("fitmentList:add"),
                    size: "mini",
                    type: "primary",
                    icon: "document",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.choiaddNotice()
                    },
                  },
                },
                [_vm._v("添加商品")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: {
                    disabled:
                      !_vm.isAuth("fitmentList:delete") ||
                      _vm.checkBoxData.length <= 0,
                    size: "mini",
                    type: "danger",
                    icon: "document",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.choideletes()
                    },
                  },
                },
                [_vm._v("批量删除")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableDataLoading,
                  expression: "tableDataLoading",
                },
              ],
              attrs: { data: _vm.choicenData.list },
              on: { "selection-change": _vm.changeFun },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", fixed: "" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "", prop: "id", label: "编号", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "", prop: "itempic", label: "商品图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: {
                            src: scope.row.itempic,
                            width: "40",
                            height: "40",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "itemtitle",
                  label: "商品名称",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: { fixed: "", prop: "couponmoney", label: "优惠券(元)" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "", prop: "itemendprice", label: "券后价(元)" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "", prop: "itemsale", label: "销量" },
              }),
              _c("el-table-column", {
                attrs: { fixed: "", prop: "shopname", label: "店铺名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "id", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              disabled: !_vm.isAuth("fitmentList:delete"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.choidelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "margin-top": "10px",
                float: "right",
              },
            },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  "current-page": _vm.page,
                  layout: "total,sizes, prev, pager, next,jumper",
                  total: _vm.choicenData.totalCount,
                },
                on: {
                  "size-change": _vm.handleSizeChange3,
                  "current-change": _vm.handleCurrentChange3,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "添加",
                customClass: "customWidth",
                visible: _vm.dialogFormVisible5,
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible5 = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    display: "inline-block",
                    float: "right",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "搜索商品" },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.shousuo.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.keyword,
                      callback: function ($$v) {
                        _vm.keyword = $$v
                      },
                      expression: "keyword",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "0",
                        top: "8px",
                      },
                      on: { click: _vm.shousuo },
                    },
                    [
                      _c("icon-svg", {
                        staticClass: "site-sidebar__menu-icon",
                        attrs: { name: "shousuo" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableDataLoading,
                      expression: "tableDataLoading",
                    },
                  ],
                  attrs: { height: "450", data: _vm.choicenData2 },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      type: "index",
                      label: "编号",
                      align: "center",
                      width: "50",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (_vm.page - 1) * _vm.limit + scope.$index + 1
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "", prop: "itempic", label: "商品图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              staticStyle: { "border-radius": "50%" },
                              attrs: {
                                src: scope.row.itempic,
                                width: "40",
                                height: "40",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "itemtitle",
                      label: "商品名称",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "couponmoney",
                      label: "优惠券(元)",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "",
                      prop: "itemendprice",
                      label: "券后价(元)",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "", prop: "itemsale", label: "销量" },
                  }),
                  _c("el-table-column", {
                    attrs: { fixed: "", prop: "shopname", label: "店铺名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", prop: "id" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.releasNoticeTo(scope.row)
                                  },
                                },
                              },
                              [_vm._v("添加 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-tab-pane", { attrs: { label: "首页轮播", name: "sixth" } }, [
        _c(
          "div",
          [
            _c(
              "el-row",
              [
                _vm.isAuth("fitmentList:add")
                  ? _c("el-col", { staticClass: "cards", attrs: { span: 6 } }, [
                      _c("div", { staticClass: "adver_main box" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: { click: _vm.addNoticelun },
                          },
                          [_vm._v(" + 新增轮播图 ")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._l(_vm.bannerData, function (item, index) {
                  return _c(
                    "el-col",
                    { key: index, staticClass: "cards", attrs: { span: 6 } },
                    [
                      _c("div", { staticClass: "adver_main bannerManin" }, [
                        _c("img", { attrs: { src: item.imageUrl, alt: "" } }),
                        _c("span", [_vm._v(_vm._s(item.url))]),
                      ]),
                      _c("div", { staticClass: "bannerbtn" }, [
                        _vm.isAuth("fitmentList:update")
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function ($event) {
                                    return _vm.compilelun(item)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm.isAuth("fitmentList:delete")
                          ? _c(
                              "a",
                              {
                                attrs: { href: "javascript:;" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteslun(item.id)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "添加轮播图信息",
                  visible: _vm.dialogFormVisible6,
                  center: "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible6 = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "10px" } },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          width: "200px",
                          display: "inline-block",
                          "text-align": "right",
                        },
                      },
                      [_vm._v("链接：")]
                    ),
                    _c("el-input", {
                      staticStyle: { width: "50%" },
                      attrs: { placeholder: "请输入轮播图链接" },
                      model: {
                        value: _vm.url,
                        callback: function ($$v) {
                          _vm.url = $$v
                        },
                        expression: "url",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "10px", display: "flex" } },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          width: "200px",
                          display: "inline-block",
                          "text-align": "right",
                        },
                      },
                      [_vm._v("轮播图片：")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "148px",
                          height: "148px",
                          border: "1px dashed #c0ccda",
                          "border-radius": "6px",
                          "text-align": "center",
                          "line-height": "148px",
                        },
                      },
                      [
                        _c(
                          "custom-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action: _vm.$http.adornUrl("oss/upload"),
                              "show-file-list": false,
                              "on-success": _vm.handleAvatarSuccess,
                            },
                            model: {
                              value: _vm.imageUrl,
                              callback: function ($$v) {
                                _vm.imageUrl = $$v
                              },
                              expression: "imageUrl",
                            },
                          },
                          [
                            _vm.imageUrl
                              ? _c("img", {
                                  staticClass: "avatar",
                                  staticStyle: {
                                    "border-radius": "6px",
                                    width: "148px",
                                    height: "148px",
                                  },
                                  attrs: { src: _vm.imageUrl },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogFormVisible6 = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.releaslunNoticeTo()
                          },
                        },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "修改",
                  visible: _vm.dialogFormVisible7,
                  center: "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible7 = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  { attrs: { model: _vm.form1 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "链接：",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "65%" },
                          model: {
                            value: _vm.form1.url,
                            callback: function ($$v) {
                              _vm.$set(_vm.form1, "url", $$v)
                            },
                            expression: "form1.url",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "轮播图片：",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "148px",
                              height: "148px",
                              border: "1px dashed #c0ccda",
                              "border-radius": "6px",
                              "text-align": "center",
                              "line-height": "148px",
                            },
                          },
                          [
                            _c(
                              "custom-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  action: _vm.$http.adornUrl("oss/upload"),
                                  "show-file-list": false,
                                  "on-success": _vm.handleAvatarSuccess2,
                                },
                                model: {
                                  value: _vm.imageUrl2,
                                  callback: function ($$v) {
                                    _vm.imageUrl2 = $$v
                                  },
                                  expression: "imageUrl2",
                                },
                              },
                              [
                                _vm.form1.imageUrl
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      staticStyle: {
                                        "border-radius": "6px",
                                        width: "148px",
                                        height: "148px",
                                      },
                                      attrs: { src: _vm.form1.imageUrl },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogFormVisible7 = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.upgradbianTo()
                          },
                        },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "修改", visible: _vm.dialogFormVisible2, center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.formcomp } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "标题：", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "65%" },
                    model: {
                      value: _vm.formcomp.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formcomp, "title", $$v)
                      },
                      expression: "formcomp.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "路由：", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "65%" },
                    model: {
                      value: _vm.formcomp.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.formcomp, "url", $$v)
                      },
                      expression: "formcomp.url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "图片：", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "148px",
                        height: "148px",
                        border: "1px dashed #c0ccda",
                        "border-radius": "6px",
                        "text-align": "center",
                        "line-height": "148px",
                      },
                    },
                    [
                      _c(
                        "custom-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.$http.adornUrl("oss/upload"),
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess3,
                          },
                          model: {
                            value: _vm.imageUrl,
                            callback: function ($$v) {
                              _vm.imageUrl = $$v
                            },
                            expression: "imageUrl",
                          },
                        },
                        [
                          _vm.formcomp.imageUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                staticStyle: {
                                  "border-radius": "6px",
                                  width: "86px",
                                  height: "86px",
                                },
                                attrs: { src: _vm.formcomp.imageUrl },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.compileNoticeTo()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
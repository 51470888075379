import { render, staticRenderFns } from "./staffList.vue?vue&type=template&id=24a0bf95&scoped=true"
import script from "./staffList.vue?vue&type=script&lang=js"
export * from "./staffList.vue?vue&type=script&lang=js"
import style0 from "./staffList.vue?vue&type=style&index=0&id=24a0bf95&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a0bf95",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/prodctyun-yugao-maintenance-manage-consumer-web/code_dir-a/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24a0bf95')) {
      api.createRecord('24a0bf95', component.options)
    } else {
      api.reload('24a0bf95', component.options)
    }
    module.hot.accept("./staffList.vue?vue&type=template&id=24a0bf95&scoped=true", function () {
      api.rerender('24a0bf95', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/tuanList/staffList.vue"
export default component.exports